//-----------------------------
// Front royal modal styles
//-----------------------------

// .container is used in content admin pages while .front-royal-form-container is used
// in the new admin pages
.container,
.front-royal-form-container {
    .modal {
        display: block;
    }
}

dialog-modal-alert .modal {
    display: block;
    padding-top: $APP_HEADER_HEIGHT;

    // dark background by default
    background-color: rgba($COLOR_V3_BLACK, 0.5);

    // pop modals up below the header (at least that's what we want for reward screens)
    z-index: $MODAL_Z_INDEX;

    .modal-dialog {
        .modal-content {
            background-color: $COLOR_V3_WHITE;
            border: none;
            border-radius: 10px;
            overflow: hidden;
            z-index: 1;

            pre {
                user-select: text !important;
            }

            // the close button sometimes appears within the body for old modals, so we declare it here
            button.close {
                opacity: 1;
                position: absolute;
                right: 10px;
                top: 18px;
                border-radius: 0px;
                box-shadow: none;
                outline: none;
                z-index: 100;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: 10px;
                    right: auto;
                }

                &.close:after {
                    content: "";
                    display: inline-block;
                    background-image: url("~vectors/close_icon_beige_darker.svg");
                    background-size: 25px 25px;
                    background-repeat: no-repeat;
                    opacity: 0.5;
                    width: 25px;
                    height: 25px;
                }

                &.close:hover:after {
                    opacity: 0.65;
                }
            }

            .modal-header {
                border: none;
                padding-top: 12px 10px 16px;

                .modal-title {
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                    font-size: 24px;
                    line-height: 1.1em;
                    color: $COLOR_V3_BLACK;
                    text-align: center;
                    padding-left: 25px; // to ensure the title doesn't hit the close button
                    padding-right: 25px;
                }
            }

            .modal-body {
                font-weight: $FONT_WEIGHT_NORMAL;
                color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
                padding: 0px 30px 30px 30px;

                .center {
                    text-align: center;
                }

                p.message {
                    font-weight: $FONT_WEIGHT_NORMAL;
                    font-size: 16px;
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                    margin-bottom: 16px;
                }

                // standard modal button styling
                button.modal-secondary-button,
                button.modal-action-button {
                    height: 42px;
                    width: auto;
                    min-width: 150px;
                    border-radius: 100px;
                    box-shadow: none;
                    display: block;
                    font-weight: $FONT_WEIGHT_NORMAL;
                    font-size: 16px;
                    padding-left: 15px;
                    padding-right: 15px;
                    outline: none;
                }

                // standard single-button styling
                button.modal-action-button {
                    @include flat-button-color($COLOR_V3_GREEN, $COLOR_V3_GREEN_DARK);
                    margin: 0 auto;
                    border: 0;
                }

                button.modal-action-button.blue {
                    @include flat-button-color($COLOR_V3_BLUE, $COLOR_V3_BLUE_DARK);
                    margin: 0 auto;
                    border: 0;
                }

                // when there's no title, tweak to offset
                button.close {
                    top: 10px;
                }

                button.modal-action-button.small {
                    min-width: 100px;
                    margin: 4px;
                }

                button.modal-action-button.inline {
                    display: inline-block;
                }

                button.modal-action-button.confirm {
                    @include flat-button-color($COLOR_V3_BEIGE_DARK, $COLOR_V3_BEIGE_DARKER);

                    &.grey {
                        background: none;
                        color: $COLOR_V3_BEIGE_DARKEST;
                        font-weight: $FONT_WEIGHT_SEMIBOLD;

                        &:hover:not(:disabled) {
                            background: none;
                            color: $COLOR_V3_BEIGE_DARKER;
                        }
                    }

                    &.ok {
                        @include flat-button-color($COLOR_V3_BLUE, $COLOR_V3_BLUE_DARK);
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                        float: right;

                        &.red {
                            @include flat-button-color($COLOR_V3_RED, $COLOR_V3_RED_DARK);
                        }

                        &.no-float {
                            float: none;
                        }
                    }

                    &.delete {
                        @include flat-button-color($COLOR_V3_RED, $COLOR_V3_RED_DARK);
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                        float: right;
                    }
                }

                // the button container is a standardized class we use in two-button modals
                .button-container {
                    margin-top: 30px;
                    position: relative;
                    width: 100%;

                    button {
                        margin: 0;
                        margin-top: 10px;
                        display: block;
                        width: 100%;
                    }

                    @media (min-width: $screen-sm-min) {
                        height: 50px;

                        button.modal-secondary-button {
                            position: absolute;
                            left: 0px;
                            bottom: 0px;
                            width: 225px;
                        }

                        button.modal-action-button {
                            position: absolute;
                            right: 0px;
                            bottom: 0px;
                            width: 225px;
                        }
                    }
                }

                // when saying "or" between two buttons
                .buttons_or {
                    padding: 10px;
                    display: block;

                    @media (min-width: $screen-sm-min) {
                        display: inline-block;
                    }
                }
            }
        }
    }

    //--------------------------------
    // Sizing Modifiers
    //--------------------------------

    &.large {
        .modal-dialog {
            width: 90%;
        }
    }

    &.medium-large {
        .modal-dialog {
            @media (min-width: 690px) {
                // max-width + 10px margin on either side = 490px
                width: auto;
                max-width: 670px;
                margin: 0 auto;
            }
        }
    }

    &.medium {
        .modal-dialog {
            @media (min-width: 490px) {
                // max-width + 10px margin on either side = 490px
                max-width: 470px;
                margin: 0 auto;
            }
        }
    }

    &.medium-small {
        .modal-dialog {
            @media (min-width: $screen-sm-min) {
                max-width: 470px;
            }
        }
    }

    &.small {
        .modal-dialog {
            @media (min-width: $screen-sm-min) {
                max-width: 350px;
            }
        }
    }

    &.fullscreen {
        &,
        .modal-dialog {
            width: 100vw;
            height: 100vh;
            padding: 0;
            margin: 0px;
        }

        .modal-dialog .modal-content {
            overflow-y: auto;
            scrollbar-gutter: stable;
            height: 100%;
            border-radius: 0;
            border: none;
            box-shadow: none;

            // move down below app header
            .modal-body {
                padding-top: $APP_HEADER_HEIGHT;
            }
        }
    }

    //--------------------------------
    // Animated Appearance defaults
    //   (fade in and slide up)
    //--------------------------------

    &.fade {
        opacity: 1;
        transition: none;

        .modal-dialog {
            opacity: 0;
            transform: translate(0, 80px);
            transition: transform 0.3s ease-out, opacity 0.3s ease-out;
        }
    }
    &.in {
        .modal-dialog {
            opacity: 1;
            transform: translate(0, 0);
            transition: transform 0.3s ease-out, opacity 0.3s ease-out;
        }
    }

    //--------------------------------
    // Styling Modifiers
    //--------------------------------

    // allow overflow (useful for long dropdowns)
    &.overflow-visible {
        .modal-dialog .modal-content {
            overflow: visible;
        }
    }

    // if no title specified, the header is hidden. add padding to account.
    &.no-header {
        @media (max-width: $screen-sm-max) {
            padding-top: 45px;
        }

        .modal-dialog .modal-content .modal-body {
            padding-top: 45px;
        }
    }

    // overrides for the modal-action-button button
    &.blue {
        .modal-dialog .modal-content .modal-body button.modal-action-button {
            @include flat-button-color($COLOR_V3_BLUE, $COLOR_V3_BLUE_DARK);
        }
    }

    // don't show the title because the title will come from the body
    &.no-title {
        .modal-title {
            display: none;
        }

        .modal-content {
            overflow: visible;
        }
    }

    // Normally there's some padding around the content body, this removes that
    // padding so that the content will be extend to the sides of the modal body.
    &.no-body-padding {
        .modal-dialog .modal-content .modal-body {
            padding: 0;
        }
    }

    // By default, our dialog modals overlay on top of the entire viewport,
    // disabling access to any UI elements "behind" the modal (any elements
    // with a lower z-index value). This class sets the z-index to the same
    // value as the app header/menu, so users can still access the header/menu
    // controls and navigate to other areas of the app.
    // NOTE: If a blur target has been supplied to the dialog modal, you'll
    // most likely want to make sure that the blur target is specific enough
    // so that the app header/menu won't be blurred.
    &.clickable-app-header-menu {
        // Prevents the modal from blocking access to the app header/menu.
        z-index: $APP_HEADER_Z_INDEX;

        @media (min-width: $screen-sm-min) {
            // Make sure that we bump the modal down the same height as the app header so that the modal
            // doesn't block access to the app header.
            margin-top: $APP_HEADER_HEIGHT;
        }
    }
}

//--------------------------------
// ExecEd Cert Congrats Modal
//--------------------------------

dialog-modal-alert .modal.exec-ed-cert-congrats-modal {
    padding: 0;
    padding-left: 0px !important;
    .modal-dialog {
        .modal-content {
            .modal-body {
                padding: 0px;
            }
        }
    }
}

//--------------------------------
// Badge
//--------------------------------

.avatar-badge {
    .modal-header {
        padding-top: 50px;
    }

    .modal-content {
        overflow: visible !important;
    }

    .modal-body {
        position: static; // must be static to vertically align the careers avatar properly (see .badge class)
    }

    .badge {
        width: 75px;
        height: 75px;
        position: absolute;
        background-color: $COLOR_V3_WHITE;
        margin: 0 auto;
        top: 0px;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
        text-align: center;
    }
}

//--------------------------------
// Lesson Grader
//--------------------------------

.lesson-grader {
    .modal-dialog .modal-content .modal-body {
        padding: 15px;
    }
}

//--------------------------------
// Image Zooming
//--------------------------------

dialog-modal-alert .modal.zoom {
    -webkit-overflow-scrolling: auto;
    background-color: $COLOR_V3_WHITE;

    // Remove the slide animation and do a zoom animation instead
    &.fade .modal-dialog {
        transform: translate(0, 0) scale(0);
        @include transition-transform(0.3s ease-out);
    }

    &.in .modal-dialog {
        transform: translate(0, 0) scale(1);
    }

    .modal-content {
        overflow: hidden;
        opacity: 1;

        div[data-id="contents"] {
            height: 100%;
        }

        .modal-body {
            position: relative;
            top: auto;
            bottom: auto;
            height: 100%;
        }

        .zoom-container {
            height: 100%;

            .image_wrapper {
                position: relative;
                z-index: 1; // below the close button
                height: 100%;
            }

            .cf-image {
                display: block;
                margin: 0px auto;
                max-height: 90%;
            }
        }
    }
}

//--------------------------------
// Feedback Form
//--------------------------------

dialog-modal-alert .modal.feedback-form-modal .modal-content {
    .modal-body {
        width: 100%;

        .general-error {
            color: $COLOR_V3_RED;
        }

        h2 {
            margin: 50px auto;
            text-align: center;
        }

        a {
            color: $COLOR_V3_CORAL;
        }

        textarea {
            @include font-size(16);
            padding: 16px 15px 15px;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            background-clip: padding-box;
            box-shadow: none;
            color: #615a52;
            outline: 0;
            &:active,
            &:focus {
                border: 1px solid #b2b2b2;
                box-shadow: none;
            }
            &.has-error,
            &.ng-invalid:not(.ng-pristine) {
                border: 1px solid #ff4d63;
            }
        }

        button.modal-action-button {
            width: 100%;
            margin: 0px auto;
            display: block;
        }
    }
}

//--------------------------------
// User Facing Errors
//--------------------------------

dialog-modal-alert .modal.server-error-modal {
    z-index: $SERVER_ERROR_MODAL_Z_INDEX;

    .modal-dialog .modal-content .modal-body {
        text-align: center;

        @media (max-width: $screen-xs-max) {
            padding: 0px 15px 15px 15px;
        }

        button.modal-action-button {
            margin-top: 20px;
        }

        .logged_out {
            .input-container {
                margin-bottom: 5px;
            }

            .sessions-submit {
                @include hollow-button-color($COLOR_V3_CORAL, 2px, $COLOR_V3_WHITE);
            }
        }
    }
}

//--------------------------------
// Accepted Application modal
//--------------------------------

dialog-modal-alert .modal.accepted-application-modal {
    overflow-y: hidden;
    padding: 0px !important;

    .modal-dialog .modal-content {
        text-align: center;
        opacity: 1;
        background-color: rgba(25, 70, 149, 1); // $COLOR_V3_BLUE_DARK

        @media (min-width: $screen-sm-min) {
            background-color: rgba(25, 70, 149, 0.95);
        }

        .modal-body {
            height: 100%;
            padding: 0px;
            position: relative;

            div[data-id="contents"] {
                height: 100%;
            }
        }
    }
}

//--------------------------------
// Connection Cards / Transparent
//--------------------------------

dialog-modal-alert .modal.transparent {
    padding-top: 50px;

    // not sure why the dialog modal adds a padding-left: 8px, but it messes things up on mobile
    @media (max-width: $screen-xs-max) {
        padding-left: 0px !important;
    }

    @media (min-width: $screen-sm-min) {
        padding-top: 120px;
    }

    .modal-dialog,
    .modal-dialog .modal-content,
    .modal-dialog .modal-content .modal-body {
        background-color: transparent;
        border: none;
        box-shadow: none;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: visible;
    }
}

//--------------------------------
// Share Card Modals
//--------------------------------

dialog-modal-alert .modal.share-card {
    padding-top: 100px;

    .modal-dialog {
        margin: 10px auto;
    }

    .modal-dialog,
    .modal-content {
        min-height: 297px;
    }

    .modal-dialog .modal-body {
        padding: 20px;
    }
}

dialog-modal-alert .modal.register-card {
    .modal-dialog {
        margin: 10px auto;
    }

    .modal-dialog,
    .modal-content {
        width: 300px;
        height: 206px;
    }

    .modal-dialog .modal-body {
        padding: 20px;
        padding-top: 30px !important;
    }
}

//--------------------------------
// Confirm by typing modal
//--------------------------------

dialog-modal-alert confirm-by-typing-modal {
    .front-royal-form-container .form-group.row {
        padding-left: 0px;
        padding-right: 0px;

        p.message {
            user-select: text;

            .thing {
                color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
            }
            strong {
                color: $COLOR_V3_BLACK;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }
        }

        input {
            text-align: center;
        }
    }
}

//--------------------------------
// Schedule Interview Modal
//--------------------------------
dialog-modal-alert .schedule-interview-modal-container {
    padding-left: 0px !important; // override inline styling

    .modal-dialog .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .modal-body {
            flex: 2 0 auto;

            padding-top: 15px !important;

            .contents {
                height: 100%;
            }

            .schedule-interview-modal {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 700px;
                margin: 0 auto;
                text-align: center;

                .calendly-inline-widget {
                    overflow-y: hidden !important; // override default inline styling
                    min-width: 320px;
                    height: 1600px;

                    @media (max-width: $screen-xs-max) {
                        overflow-y: scroll !important;
                        height: 500px;
                    }
                }
            }
        }
    }
}

//--------------------------------
// Offline modal
// Unlike other modals, we totally
// re-style this one.
//--------------------------------

dialog-modal-alert .modal.disconnected-modal {
    $reconnectWidth: 160px;
    $spinnerWidth: 50px;
    $spinnerRight: 24px;

    $reconnectWidthWide: 210px;
    $spinnerWidthWide: 60px;
    $spinnerRightWide: 28px;

    $modalBannerHeight: 75px;
    $modalBannerHeightWide: 50px;

    padding-top: 0px;

    .modal-dialog {
        margin: 0 auto;

        .modal-content {
            border: 0px solid transparent;
            border-radius: 0px 0px 5px 5px;

            .modal-header {
                display: none;
            }

            .modal-body {
                padding: 0px;

                .disconnected.row {
                    height: $modalBannerHeightWide;
                    color: $COLOR_V3_EGGPLANT;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;

                    .message-col {
                        position: relative;
                        height: 100%;
                        padding-right: 160px;

                        span.message {
                            display: inline-block;
                            position: absolute;
                            left: #{15px + 10px};
                            width: calc(100% - #{$reconnectWidthWide + 15px + 10px + 10px});
                            top: 50%;
                            transform: translateY(-50%);

                            // > span {
                            //     display: block;
                            // }
                        }

                        button {
                            $reconnectColor: rgba(0, 0, 0, 0.1);
                            $reconnectActiveColor: rgba(0, 0, 0, 0.05);
                            $reconnectHoverColor: rgba(0, 0, 0, 0.2);
                            @include flat-button-color($reconnectColor, $reconnectHoverColor, $reconnectActiveColor);

                            display: block;
                            position: absolute;
                            top: 0px;
                            right: $spinnerWidthWide;
                            width: #{$reconnectWidthWide - $spinnerWidthWide};
                            height: 100%;
                            font-weight: $FONT_WEIGHT_SEMIBOLD;
                            color: $COLOR_V3_EGGPLANT !important;
                            border-radius: 0px;
                            padding-left: 10px;
                            padding-right: 10px;

                            html:lang(es) & {
                                font-size: 14px;
                            }
                        }

                        .loading-spinner {
                            display: block;
                            position: absolute;
                            right: $spinnerRightWide;
                            font-size: 20px;
                            top: calc(50% - 10px);
                        }
                    }
                }
            }
        }

        // Override modal defaults forcefully here
        @media (max-width: $screen-xs-max) {
            height: auto !important;

            .modal-content {
                height: auto !important;
                border-radius: 0 !important;
                font-size: 13px;

                .modal-body {
                    position: inherit;
                    top: 0px;
                    bottom: auto;

                    .disconnected.row {
                        height: $modalBannerHeight;

                        .message-col {
                            span.message {
                                width: calc(100% - #{$reconnectWidth + 15px + 10px + 10px});
                            }
                            button {
                                font-size: 13px;
                                padding-left: 5px;
                                padding-right: 5px;
                                right: $spinnerWidth;
                                width: #{$reconnectWidth - $spinnerWidth};
                            }
                            .loading-spinner {
                                right: $spinnerRight;
                            }
                        }
                    }
                }
            }
        }
    }
}

//--------------------------------
// Offline modal
// Unlike other modals, we totally
// re-style this one.
//--------------------------------

dialog-modal-alert .modal.disconnected-modal-not-initialized {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    padding-left: 0px !important;

    .modal-dialog,
    .modal-content,
    .modal-body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-header {
        display: none;
    }
}

//--------------------------------
// ngToast Handling
//--------------------------------

body.dialog-modal {
    app-shell #sp-page toast,
    app-shell + div > toast {
        display: none;
    }
}

//--------------------------------
// program change warning
//--------------------------------

dialog-modal-alert .modal.program-change-warning .modal-dialog .modal-content {
    .modal-body {
        padding: 45px 30px;
    }

    p {
        margin: 25px auto;
        max-width: 430px;
        color: $COLOR_V3_GREY_DARKER;

        b {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }
    }

    .unstyled-button {
        text-decoration: underline;
    }
}

//--------------------------------
// welcome to dashboard
//--------------------------------

dialog-modal-alert .modal.admissions-process-modal .modal-dialog .modal-content {
    .admissions-process-modal-wrapper {
        // This forces the layout of two columns on mobile
        display: flex;

        @media (min-width: $screen-sm-min) {
            display: block;
        }
    }

    .flex-responsive {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        > * {
            flex: 1 1 0px;

            @media (max-width: $screen-xs-max) {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            align-items: center;
        }
    }

    .icon-item {
        // centers the images vertically in mobile
        display: flex;
        align-items: center;
    }

    a {
        color: $COLOR_V3_BLUE;
    }

    img {
        display: block;
        float: left;
        margin-right: 20px;
        width: 36px;

        &.graduation-cap {
            width: 115px;
        }

        @media (min-width: $screen-sm-min) {
            margin: 20px auto;
            float: none;
            width: auto;

            &.three-heads {
                width: 100px;
            }
        }

        // This icon is odd because of the box with the pencil
        // sticking out of it.
        @media (max-width: $screen-xs-max) {
            &.complete-application {
                width: 40px;
                margin-left: -4px;
            }

            &.graduation-cap {
                width: 40px;
                min-width: 40px;
            }
        }
    }

    p {
        margin: 0;

        @media (min-width: $screen-sm-min) {
            text-align: center;
            margin: 20px 0 10px;
        }
    }

    .modal-body {
        @include clearfix;

        @media (max-width: $screen-sm-max) {
            padding: 0 20px 20px;

            [class^="col"] {
                padding: 0;
                margin: 10px 0;
            }
        }
    }
}

//--------------------------------
// Editor Hints Modals
//--------------------------------

dialog-modal-alert .modal.editor-hints {
    .modal-dialog,
    .modal-content {
        width: 700px;
    }
}

//--------------------------------
// ID Verification
//--------------------------------

dialog-modal-alert .modal.id-verification {
    .modal-dialog,
    .modal-content {
        width: 600px;
    }
}

//--------------------------------
// Cover Letter
//--------------------------------

dialog-modal-alert .modal.cover-letter {
    @media (max-width: $screen-sm-max) {
        padding-top: 15px;
    }

    .modal-header {
        @media (min-width: $screen-sm-min) {
            padding: 30px 15px;
        }
    }

    .modal-dialog .modal-content .modal-body {
        padding: 0;
    }

    position-card {
        padding: 30px;
    }
}

dialog-modal-alert .modal.manage-position {
    .modal-dialog {
        @media (min-width: 730px) {
            // max-width + 10px margin on either side
            width: auto;
            max-width: 750px;
            margin: 0 auto;
        }
    }

    h2 {
        text-align: center;
        margin: 0;
        color: $COLOR_V3_BLACK;
        font-size: 24px;
    }

    position-expiration-message {
        display: block;
        text-align: center;
        margin: 15px 0;
    }

    position-card {
        border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
        margin: 30px 0;
    }

    .button-caption {
        text-align: center;

        button {
            margin-bottom: 15px;
        }
    }
}

//--------------------------------
// Student Network Event Details
//--------------------------------

dialog-modal-alert .modal.student-network-event-details {
    @media (max-width: ($screen-xs-min - 1)) {
        height: calc(100% - #{$APP_MENU_MOBILE_HEIGHT});
        margin-bottom: $APP_MENU_MOBILE_HEIGHT;

        // Not sure why the dialog modal adds a padding-left: 8px, but it messes things up on mobile.
        // Regardless, we want this modal to occupy the entire screen above the mobile app menu on
        // mobile, so just remove all of the padding.
        padding: 0px !important;
        overflow-y: hidden; // prevents the image scrolling out of the viewport
    }

    // Anonymized events have many event details hidden entirely,
    // which means we don't need the modal content to expand to fill up
    // to the 600px max-height.
    &.anonymized .modal-dialog {
        @media (min-width: $screen-xs-min) {
            height: auto;
        }
    }

    .modal-dialog {
        // Saw an intermittent UI bug during local development where the app header was showing at the
        // top of the screen over top of the modal close button, so you couldn't close the modal. Setting
        // the z-index to $MODAL_Z_INDEX, which is greater than $APP_HEADER_Z_INDEX, should fix the issue.
        z-index: $MODAL_Z_INDEX;
        height: 100%;
        width: 100%;

        @media (max-width: $screen-xs-min) {
            margin: 0px;
        }

        // Specific styling for the modal on desktop and large mobile screen sizes.
        @media (min-width: $screen-xs-min) {
            margin: 30px auto;
            max-height: 600px;
            width: 371px;
        }

        .modal-content {
            border-radius: 0px;
            box-shadow: none;
            height: 100%;

            @media (min-width: $screen-xs-min) {
                border-radius: 10px;
            }

            .modal-body {
                height: 100%;

                button.close {
                    top: 17px;
                    right: 17px;
                    width: 34px;
                    height: 34px;
                    border: 0px solid transparent;
                    border-radius: 50%;
                    background: rgba(0, 0, 0, 0.5);

                    &:after {
                        content: "✕";
                        color: white;
                        background: none;
                        opacity: 1;
                        height: auto;
                        width: auto;
                        text-shadow: none;
                        line-height: 100%;
                        font-weight: normal;
                        font-size: 21px;
                    }
                }
            }
        }
    }
}

//--------------------------------
// Upload Unofficial Transcripts
//--------------------------------

dialog-modal-alert .modal.upload-unofficial-transcripts {
    .modal-content {
        // There's a rather large tooltip that can show up in the modal,
        // so we need to make sure that it can extend outside of the modal.
        overflow: visible;
    }
}

//----------------------------------
// Registration - Bank Details Modal
//----------------------------------
dialog-modal-alert .modal.bank-details {
    .modal-content {
        dl {
            margin: 20px 0;
            dd {
                margin-bottom: 15px;
            }
        }
    }
}

//----------------------------------
// Registration - Billing Options Modal
//----------------------------------
dialog-modal-alert .modal.billing-options {
    .modal-content {
        h3 {
            font-size: 18px;
            font-weight: bold;
            margin: 15px 0 3px;
        }
        .refund-policy {
            margin: 15px 0 0 0;
        }
    }
}

//--------------------------------
// A/B Test - Decline Scholarships Modal
//--------------------------------

dialog-modal-alert .modal.decline-scholarships-modal {
    .decline-modal-body {
        text-align: center;

        .decline-modal-action-buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .confirm-button {
                margin: 20px 0;
                text-transform: uppercase;
            }

            .close-and-review-button {
                background: none;
                border: none;
                color: $COLOR_V3_BLUE;
            }
        }
    }
}
