// FIXME: Reconcile the hover styles in here with connections_buttons.scss and flat_button.scss
// Having to use some !importants and stuff. I think we could unify this a bit better to make it
// easier in the future when making buttons.

button.unstyled-button {
    border: none;
    @include remove-outlines;
    cursor: pointer;
    text-decoration: none;
    background: transparent;
    padding: 0px;
    display: inline;
    margin: 0px;
    appearance: none;
    text-align: inherit;

    &:disabled {
        cursor: inherit;
    }

    &:hover:not(:disabled):not(.grey) {
        .no-touchevents & {
            opacity: 0.6;
        }
    }

    &:active:not(:disabled) {
        .no-touchevents & {
            opacity: 0.3;
        }
    }

    &.grey:hover:not(:disabled) {
        .no-touchevents & {
            color: $COLOR_V3_BLUE;
        }
    }
}
