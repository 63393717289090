// On staging/local, we want to make it obvious this is not the production environment
html {
    &.local,
    &.staging {
        $instanceBannerHeight: 10px;

        // put the banner in this space, on top of all content, even fixed position things
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: $instanceBannerHeight;
            z-index: 50000;
        }
    }

    &.local {
        &:after {
            background: repeating-linear-gradient(
                135deg,
                $COLOR_V3_DARK_GREY,
                $COLOR_V3_DARK_GREY 10px,
                $COLOR_V3_TURQUOISE 10px,
                $COLOR_V3_TURQUOISE 20px
            ) !important;
        }
    }

    &.staging {
        &:after {
            background: repeating-linear-gradient(
                45deg,
                $COLOR_V3_YELLOW,
                $COLOR_V3_YELLOW 10px,
                $COLOR_V3_BLUE 10px,
                $COLOR_V3_BLUE 20px
            ) !important;
        }
    }
}
