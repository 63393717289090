@use "sass:math";

@mixin font-size($sizeValue) {
    $pxValue: $sizeValue;

    // We have to remove the units here so that we can divide
    // if someone passes in just a number without a pixel value
    $defaultFontSizeWithoutUnits: math.div($FONT_SIZE_DEFAULT, 1px);
    $remValue: math.div(round(1000000 * math.div($sizeValue, $defaultFontSizeWithoutUnits)), 1000000);
    font-size: #{$pxValue}px;
    font-size: #{$remValue}rem;
}

@mixin background-image2x($normalPath, $retinaPath) {
    background-image: url($normalPath);
    @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
        background-image: url($retinaPath);
    }
}

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}
