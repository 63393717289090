.add-an-item {
    font-size: 14px;

    input[type="text"] {
        padding-left: 14px;
        margin-bottom: 5px;
        width: calc(100% - 68px) !important;

        html:not([dir="rtl"]) & {
            margin-right: 5px;
        }
        html[dir="rtl"] & {
            margin-left: 5px;
        }

        @media (max-width: $screen-xs-max) {
            width: calc(100% - 60px) !important;
        }

        &:disabled {
            background-color: $COLOR_V3_WHITE;
            opacity: 0.5;
        }
    }

    .form-error {
        padding: 0px 20px;
        margin-top: 0px;
    }

    .selectize-dropdown {
        .option-icon,
        .option {
            display: inline-block;
            margin-top: 8px;
        }
        $selectizePadding: 13px;
        width: calc(100% - 68px - #{$selectizePadding}) !important;
        @media (max-width: $screen-xs-max) {
            width: calc(100% - 60px) !important;
        }
    }

    button[name="add-item"] {
        min-width: 56px;
        width: 56px;
        margin: 0;
        text-align: center;
        padding: 2px;

        html:lang(it) & {
            font-size: 12.5px;
        }
    }

    .add-tooltip {
        position: relative;
        display: block;
        font-size: 12px;
        border: none;
        background-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        color: $COLOR_V3_WHITE;
        margin: 0;
        top: -120px;
        margin-bottom: -46px;

        @media (min-width: $screen-sm-min) {
            top: -105px;
            margin-bottom: -28px;
        }

        &,
        .popover-content {
            max-width: 435px;
            width: 435px;
            height: 38px;

            @media (max-width: $screen-xs-max) {
                max-width: 260px;
                width: 260px;
                height: 56px;
            }
        }

        &.popover.top > .arrow:after {
            border-top-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        }
    }

    .item {
        padding: 0;
        vertical-align: top;
        position: relative;
        margin: 5px 0px;
        width: 100%;
        min-height: 48px;
        border: 0px solid transparent;
        border-radius: 4px;

        // last item doesn't get a bottom margin
        &:last-of-type {
            margin-bottom: 0px;
        }

        .click-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
        }

        img,
        div[contenteditable] {
            vertical-align: top;
        }

        .arrow-down,
        .arrow-up,
        .trashcan,
        .commit-checkbox {
            margin: 0;
            position: absolute;
            top: 18px;
        }
        .arrow-up {
            left: 13px;
        }
        .arrow-down {
            left: 30px;
        }
        .trashcan {
            top: 16px;
            right: 16px;
        }
        .commit-checkbox {
            top: 10px;
            right: 10px;
        }

        &.in-place-edit {
            border: $SMARTLY_FORM_BORDER_STYLE;
            background-color: $COLOR_V3_WHITE;

            &.editing {
                border: $SMARTLY_FORM_FOCUSED_BORDER_STYLE;
            }

            &.invalid {
                border: 1px solid $COLOR_V3_RED;
            }
        }

        span {
            margin-left: 6px;
        }

        textarea[name="update-item"] {
            display: block;

            margin: 0;
            margin-left: 42px;

            padding: 15px 10px 10px;

            border: none;
            background-color: transparent !important;

            width: calc(100% - 70px - 35px);
            min-height: 0;
            height: 46px;
            opacity: 1;

            appearance: none;
            outline: none;
            overflow-y: hidden;
            resize: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
