pills-select #pills-sections {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;

    $fontSize: 13px;

    .pills-container {
        width: 100%;
        margin-bottom: 30px;
    }

    label {
        font-size: 18px !important;
        margin-bottom: 10px !important;
    }

    .pill {
        margin: 0px 3px 5px;
        display: inline-block;

        span {
            border-radius: 9999px;
            background-color: white;
            color: $COLOR_V3_GREY_DARK;
            border: 1px solid $COLOR_V3_GREY_DARK;
            display: block;
            padding: 4px 9px 3px;
            font-size: $fontSize;
            line-height: $fontSize;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            cursor: pointer;
            transition: 0.25s ease;
        }

        &:not(.disabled):not(.selected):hover span {
            color: $COLOR_V3_GREY_DARKER;
            border: 1px solid $COLOR_V3_GREY_DARKER;
        }

        &.selected {
            span {
                color: white;
                background-color: $COLOR_V3_GREY_DARK;
            }

            &:hover span {
                background-color: $COLOR_V3_GREY_DARKER;
            }
        }

        &.disabled:not(.selected) span {
            opacity: 0.5;
        }
    }

    &.interests {
        label {
            font-weight: $FONT_WEIGHT_NORMAL;
        }

        .pill {
            span {
                border-radius: 4px;
                padding: 4px 5px 3px;
                color: $COLOR_V3_BEIGE_DARKEST;
                border: 1px solid $COLOR_V3_BEIGE_DARKEST;

                &::before {
                    content: "#";
                    font-family: Arial, sans-serif;
                    margin-right: 5px;
                }
            }

            &:not(.disabled):not(.selected):hover span {
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                border: 1px solid $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
            }

            &.selected {
                span {
                    color: white;
                    background-color: $COLOR_V3_BEIGE_DARKEST;
                }

                &:hover span {
                    background-color: $COLOR_V3_BEIGE_BEYOND_DARK;
                }
            }
        }
    }
}
