.front-royal-form-container {
    $selectizePadding: 13px;
    $inputFontSize: 14px;
    $selectWidth: 280px;
    $checkboxSize: 16px;
    $checkboxLabelLeftMargin: 12px;
    $inputHeight: 48px;

    @include front-royal-form-sizes(
        $inputHeight: 48px,
        $selectizePadding: 13px,
        $inputFontSize: 14px,
        $checkboxSize: 16px,
        $checkboxLabelLeftMargin: 12px
    );

    // Remove IE clear button
    // See https://stackoverflow.com/a/23067969/1747491
    input::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    // Fix wonky iOS stuff
    // See http://stackoverflow.com/a/23211766/1747491
    // See http://stackoverflow.com/a/7879177/1747491
    // FIXME: is there a reset.css or something where this should move?
    input[type="text"],
    input:not([type]),
    input[type="date"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
        appearance: none;
    }

    input[type="checkbox"],
    input[type="radio"] {
        border-radius: 0;
        background-color: transparent;
        border: none;
    }

    textarea {
        appearance: none;
    }

    label {
        span {
            pointer-events: none;
        }
    }

    hr {
        border-bottom: 1px solid $COLOR_V3_BEIGE_MIDDARK;
    }

    overflow: visible; // possible to have a selectzize, etc extend out

    // Sub-Containers

    .split-group {
        &.left {
            @media (min-width: $screen-sm-min) {
                html:not([dir="rtl"]) & {
                    padding-left: 0;
                }
                html[dir="rtl"] & {
                    padding-right: 0;
                }
            }
            @media (max-width: $screen-xs-max) {
                padding: 0;
            }

            &.has-desc label {
                padding-top: 2px;
            }
        }
        &.right {
            @media (min-width: $screen-sm-min) {
                html:not([dir="rtl"]) & {
                    padding-right: 0;
                }
                html[dir="rtl"] & {
                    padding-left: 0;
                }
            }
            @media (max-width: $screen-xs-max) {
                padding: 0;
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                text-align: left;
            }
        }
    }

    .multi-select,
    .add-an-item,
    .selectize-control,
    .date-with-fallback,
    select,
    input,
    textarea {
        &.wide {
            width: 100%;

            .selectize-control {
                width: 100%;
            }

            optgroup {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                color: light-dark($COLOR_V3_DARK_GREY, $COLOR_V3_GREY);
            }
        }

        &.browse-form-input {
            // $SMARTLY_FORM_MARGIN_DEFAULT + 2px
            // <option> within select has 2px padding
            padding-left: 12px !important;
            font-size: $inputFontSize;
        }
    }

    .single-check,
    .inline-checks {
        label {
            padding: 0;
        }

        input,
        span {
            vertical-align: middle;
        }

        input {
            margin-top: 2px;
        }
    }

    .inline-checks {
        padding-top: 35px;
        padding-right: 0;

        &.inline-checks-desktop {
            @media (min-width: $screen-sm-min) {
                padding-top: 10px;
                margin-bottom: -25px;
                position: relative;
                z-index: 2;
            }
        }
    }

    // Button Styling
    button.flat {
        min-width: 100px;
        text-transform: none;

        // font-weight: $FONT_WEIGHT_NORMAL;
        // font-size: 16px;
        // padding: 6px 18px;

        // You may think it's odd that this style is called
        // full-width but it does not do anything to the width.
        // The reason for that is that, at least up to now,
        // we always use this on a button that also has a col-..
        // style, and so that is responsible for setting the width.
        // In these cases, though, we need to set the height to
        // make it look good.  So the rule here is: "when you have
        // a full-width button, it should be 50px tall so that it
        // looks nice"
        &.full-width {
            height: 50px;
            font-size: 22px;

            // If there are two of these full-width buttons on top of each other,
            // put a margin between
            + button.flat.full-width {
                margin-top: 10px;
            }
        }

        &.auto-width {
            min-width: auto;
        }
    }

    input + button.flat {
        vertical-align: top;
    }

    // Form components

    .required:not(.selectize-input):after {
        margin-left: $SMARTLY_FORM_MARGIN_DEFAULT - 4; // the non-breaking space prepended to the asterisk adds a little padding to the left, so we offset it
        color: $COLOR_V3_RED;
        content: "\00a0*"; // prepend non-breaking space to prevent :after pseudo element from wrapping on new line by itself
    }

    label.readonly {
        color: $COLOR_V3_GREY_DARK;
    }

    .required-warning {
        color: $COLOR_V3_RED;

        &.visible-xs {
            text-align: center;
            margin-bottom: $SMARTLY_FORM_MARGIN_DEFAULT * 2;
        }

        &.hidden-xs {
            margin-left: $SMARTLY_FORM_MARGIN_DEFAULT * 2;
            line-height: 35px;
        }
    }

    .form-group {
        font-size: 16px;
        position: relative;

        // this is probably silly, but it came to be this way
        // over time.  If you add .row, you are adding a border which
        // you can take away by adding .no-border.  If you do
        // not want to add .row but you want a border, then you
        // can add .border
        &.border,
        &.row:not(.no-border) {
            border-bottom: $SMARTLY_FORM_BORDER_STYLE;
            padding-bottom: $SMARTLY_FORM_SPACING_ABOVE_BORDER;
        }

        &.pull-up {
            margin-top: -42px;
        }

        &.less-space {
            margin-bottom: 0;
        }

        &.row {
            padding: 15px 56px 0px 56px;
            margin-left: 0;
            margin-right: 0;

            // no need for a margin; they sit at the bottom, where there's a padding on the main-box
            &.form-actions {
                margin-bottom: 0px;

                .hide-mobile-menu & {
                    padding-bottom: 50px;
                }
            }

            @media (max-width: $screen-xs-max) {
                padding: 7px 20px 0px 20px;
            }

            .tel-input-wrapper {
                padding: 0px !important;

                .form-error {
                    float: left;
                    margin: 0px 0px 0px 20px;
                    padding: 10px 0px 0px 0px;
                }
            }

            // When a select, multi-select, add-an-item, etc. box on the right side of a two-column
            // layout, it makes more sense to let the row set the margins
            select,
            .multi-select,
            .add-an-item {
                margin-bottom: 0px;
            }

            select-with-other {
                select.showing-other {
                    margin-bottom: 5px;
                }
            }

            input[required]:not([type="radio"]):not([type="checkbox"]),
            write-text-about {
                &.ng-invalid-maxlength {
                    p.sub-text {
                        color: $COLOR_V3_RED;
                    }
                }

                &.ng-invalid-minlength {
                    p.sub-text span {
                        color: $COLOR_V3_RED;
                    }
                }

                &.ng-invalid-language {
                    input {
                        border-color: $COLOR_V3_RED;
                    }
                }
            }

            .item-groups-editor-wrapper,
            .locale-pack-selectize-wrapper {
                padding: 0px !important;
            }
        }

        &.header:not(.no-border) {
            color: $COLOR_V3_BLACK;
            font-size: 26px;
            text-align: center;
            padding: 26px;
            margin-bottom: 15px;

            span {
                display: block;
                font-size: 14px;
                color: $COLOR_V3_BEIGE_DARKEST;
            }
        }

        [class*="col-"] label {
            &.shy {
                color: $COLOR_V3_BLACK;
                opacity: 1;
                transform: translateY(0);
                transition: all 0.3s;

                // If the next sibling is a disabled select, dim and lower the label
                &:has(+ select:disabled) {
                    color: $COLOR_V3_BEIGE_BEYOND_DARK;
                    opacity: 0.5;
                    transform: translateY(5px);
                }
            }

            &.checkbox {
                padding-left: 6px;
                padding-top: 8px;
                margin-top: 0;
                font-size: 14px;
            }
        }

        // Use this when you have a group with
        // one or more checkboxes/radio buttons on the left and
        // then labels for each input on the right
        &.checkbox-group {
            /*
                We position the input outside of the label, which let's the
                checkbox-group-label-wrapper sit to the right of
                it.  What we don't want is for two lines of text
                to wrap below the input
            */
            input {
                vertical-align: top;
                display: inline-block;
            }

            // This is a bit confusing because apparently bootstrap
            // wants us to put the checkboxes INSIDE the label.  This
            // makes it so clicking on the label will toggle the input
            label {
                position: relative;

                html[dir="rtl"] & {
                    text-align: right;
                }
            }

            // In cases where this only one line of text, this wrapper
            // seems silly, but sometimes we have a bolded line and then
            // an unbolded subtext. See /settings/notifications.  Trying to
            // keep the text from wrapping under the checkbox.  The checkbox
            // should stay to the left
            .checkbox-group-label-wrapper {
                display: inline-block;

                &.disabled {
                    opacity: 0.5;
                }
            }

            // For checkbox groups that have a dominant value whose checkbox
            // is checked, all other checkbox labels in the group should have
            // the $COLOR_V3_BEIGE_BEYOND_DARK color applied.
            &.group-with-dominant-value {
                .dominant-value-checked:not(.dominant-value) {
                    ~ .checkbox-group-label-wrapper {
                        > span {
                            color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        }
                    }
                }
            }
        }

        &,
        &.row {
            // when a multi-select is the first thing in a group,
            // remove the top margins
            > multi-select,
            > .selectize-control:not(.wide) {
                margin-top: 0px;
            }
        }
    }

    .control-title {
        display: block;
        margin-bottom: 5px;
        text-align: left;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        color: $COLOR_V3_BLACK;
        padding-left: 0;

        // rtl-language overrides
        html[dir="rtl"] & {
            text-align: right;
        }
    }

    .caption-text {
        font-size: 15px;
        color: $COLOR_V3_GREY_DARKER;
    }

    .sub-text {
        color: $COLOR_V3_BEIGE_DARKEST;
        font-size: 14px;
        margin-bottom: $SMARTLY_FORM_MARGIN_DEFAULT;

        &.post-text {
            margin-top: $SMARTLY_FORM_MARGIN_DEFAULT * 1.5;
            margin-bottom: 0;
        }

        &.dark {
            color: $COLOR_V3_BLACK;
        }
        &.red {
            color: $COLOR_V3_RED;
        }
        &.green {
            color: $COLOR_V3_GREEN;
        }

        b {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }
    }

    label,
    .label-like {
        display: block;
        margin-bottom: 0px;
        color: $COLOR_V3_BLACK;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        padding: 0;
        font-size: 16px;
        padding-top: 10px;
        margin-bottom: 5px;

        span {
            font-weight: $FONT_WEIGHT_NORMAL;
        }

        a {
            color: $COLOR_V3_BLUE;
        }
    }

    .label-like {
        @include clearfix;
    }

    // FIXME: I initially tried setting the above `label` selector to `label:not(.MuiFormLabel-root)`, but that raised its specificity
    // and caused unintended changes to other `label`'s scattered throughout our app. Ideally, we should try to figure a way to
    // ignore **all** of the styles in this file when interacting with a Material-UI form, but I'm not sure what's the best way to go
    // about that for now.
    label.MuiFormLabel-root {
        margin-bottom: initial;
        color: grey;
        font-weight: initial;
        padding: initial;
    }

    write-text-about .title {
        text-align: left;
        font-weight: $FONT_WEIGHT_SEMIBOLD;
        color: $COLOR_V3_BLACK;
        padding-left: 0;

        // rtl-language overrides
        html[dir="rtl"] & {
            text-align: right;
        }
    }

    h2 {
        font-size: 20px;
        text-align: left;
        font-weight: $FONT_WEIGHT_NORMAL;
        color: $COLOR_V3_BLACK;

        // rtl-language overrides
        html[dir="rtl"] & {
            text-align: right;
        }
    }

    .center h2 {
        text-align: center;
    }

    textarea,
    input[type="text"],
    input:not([type]),
    input[type="date"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="tel"],
    select,
    div[contenteditable] {
        border: $SMARTLY_FORM_BORDER_STYLE;
        border-radius: 4px;
        outline: none;
        padding: 8px;
        box-shadow: none;
        color: $COLOR_V3_BLACK;

        &.no-value,
        &::placeholder {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
        }

        &:focus {
            border: $SMARTLY_FORM_FOCUSED_BORDER_STYLE;
        }

        &[disabled] {
            opacity: 0.5;
            background-color: $COLOR_V3_WHITE;
        }
    }

    div[contenteditable] {
        user-select: text;
    }

    // select "placeholder"
    select[disabled],
    select[required]:invalid {
        // :invalid needs required -- http://stackoverflow.com/a/8442831
        color: $COLOR_V3_BEIGE_BEYOND_DARK !important;
    }

    textarea {
        min-height: 60px;
        resize: vertical;

        &.sm-tall-min-height {
            min-height: 90px;
        }

        &.sm-taller-min-height {
            min-height: 150px;
        }

        @media (min-width: $screen-md-min) {
            &.md-tall-min-height {
                min-height: 120px;
            }

            &.sm-taller-min-height {
                min-height: 200px;
            }
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        margin-top: 0px;
        cursor: pointer;
        appearance: none;
        outline: 0 !important; // needed to remove blue outline in Chrome
        border: none;
        box-shadow: none; // needed to prevent a weird focus ring in Firefox
        margin-right: $SMARTLY_FORM_MARGIN_DEFAULT;
        // tricky part 1: if we're in a browser that doesn't support custom checkbox/radio styling, offset down
        // so that it lines up correctly with the surrounding text. (see tricky part 2 below)
        margin-top: 4px;

        [dir="rtl"] & {
            margin-right: 0;
            margin-left: $SMARTLY_FORM_MARGIN_DEFAULT;
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    input[type="checkbox"]:before,
    input[type="radio"]:before {
        content: "";
        display: inline-block;
        padding: 0;
        margin: 0;
        vertical-align: bottom;
        position: relative;
        // tricky part 2: if we're in a browser that lets us custom style the :before, offset upward
        // to account for for margin on the parent element, plus -2px to make it line up right with the text
        top: -6px;
        *overflow: hidden;
        background-size: 100%;
        background-repeat: none;

        // rtl-language overrides
        html[dir="rtl"] & {
            margin-left: $SMARTLY_FORM_MARGIN_DEFAULT;
            margin-right: 0;
        }
    }

    input[type="checkbox"]:before {
        background-image: url("~vectors/checkbox_unchecked_beige.svg");
    }
    input[type="checkbox"]:checked:before {
        background-image: url("~vectors/checkbox_checked_beige.svg");
    }

    input[type="checkbox"]:focus:before {
        border: 1px solid $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    // custom checkbox styled as a switch toggle
    .checkbox-switch {
        input[type="checkbox"] {
            height: 0;
            width: 0;
            margin: 0;
            visibility: hidden;
        }

        label {
            cursor: pointer;
            text-indent: -9999px;
            width: 60px;
            height: 31px;
            background: $COLOR_V3_BEIGE_BEYOND_DARK;
            display: block;
            border-radius: 100px;
            position: relative;
            float: right;
            margin: 0;
            padding: 0;

            [dir="rtl"] & {
                float: left;
            }
        }

        label:after {
            content: "";
            position: absolute;
            display: block;
            top: 3px;
            left: 3px;
            width: 25px;
            height: 25px;
            background: #fff;
            border-radius: 100px;
            transition: 0.3s transform;
            will-change: transform;
        }

        input:checked + label {
            background: $COLOR_V3_GREEN;
        }

        input:checked + label:after {
            transform: translateX(116%);
        }
    }

    input[type="radio"]:before {
        background-image: url("~vectors/radio_unchecked_beige.svg");
    }
    input[type="radio"]:checked:before {
        background-image: url("~vectors/radio_checked_beige.svg");
    }

    input[type="radio"].partially-filled-version {
        width: 22px;
        height: 22px;

        &:before {
            width: 22px;
            height: 22px;
            top: 0;
            background-image: url("~vectors/radio_unchecked_beige_alt.svg");
        }

        &:checked:before {
            background-image: url("~vectors/radio_checked_partially_filled_blue.svg");
        }
    }

    input[type="tel"] {
        // Fix shadow that iOS places on the top of inputs
        // See http://stackoverflow.com/a/23211766/1747491
        appearance: none;
    }

    select {
        appearance: none;
        padding: 5px;
        background: url(~vectors/chevron_down_beige_thin.svg) no-repeat right 12px center;
        background-color: $COLOR_V3_WHITE;
        margin-bottom: 5px; // this is a little silly.  But I'm trying to make it seem like our selectizes
        display: inline-block;
        padding-right: 35px; // make sure the text does not go behind the chevron
        cursor: pointer;

        // rtl-language overrides
        html[dir="rtl"] & {
            padding-right: 5px;
            padding-left: 35px;
            background-position: 12px center;
        }

        &:disabled {
            background-color: $COLOR_V3_WHITE;
            opacity: 0.5;
        }
    }

    select::-ms-expand {
        display: none;
    }

    multi-select.wide {
        &,
        .multi-select,
        select,
        .selectize-control {
            width: 100%;
        }
    }

    .selectize-control {
        padding: 0;
    }

    .selectize-input {
        border: $SMARTLY_FORM_BORDER_STYLE;
        border-radius: 4px;
        display: block;

        &.focus {
            border: $SMARTLY_FORM_FOCUSED_BORDER_STYLE;
        }
    }

    @media (max-width: $screen-xs-max) {
        .selectize-control + select {
            margin-top: 10px;
        }
    }

    .selectize-control.single .selectize-input {
        // rtl-language overrides
        html[dir="rtl"] & {
            padding: 8px 12px 8px 22px;

            input {
                float: right;
            }

            &::after {
                left: 12px;
            }
        }

        &:after {
            right: 12px;
            background: url(~vectors/chevron_down_beige_thin.svg);
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: right;
        }
        .item {
            max-width: calc(100% - 20px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .selectize-dropdown-content {
        // rtl-language overrides
        html[dir="rtl"] & {
            text-align: right;
        }
    }

    location-autocomplete {
        padding: 0;
    }

    choose-a-date .maxdate-error {
        display: none;
    }

    // only call out after form submission
    .ng-submitted {
        textarea,
        input[type="text"],
        input:not([type]),
        input[type="date"],
        input[type="email"],
        input[type="password"],
        input[type="number"],
        select,
        div[contenteditable] {
            &.ng-invalid {
                border-color: $COLOR_V3_RED;
            }
        }

        /*
            For now at least, when we use the invalid-warning, we show it
            even before submit (when the item is invalid), but we only turn
            it red after submit.  We might have other situations one day where
            we want to hide the message entirely before submit.  I leave that
            to you to solve should it come up.
        */
        .invalid-warning {
            color: $COLOR_V3_RED;
        }

        multi-select.ng-invalid select {
            border-color: $COLOR_V3_RED;
        }

        input[type="checkbox"].ng-invalid:before {
            background-image: url("~vectors/checkbox_unchecked_red.svg");
        }

        input[type="radio"].ng-invalid:before {
            background-image: url("~vectors/radio_unchecked_red.svg");
        }

        add-an-item {
            &.ng-invalid {
                input[name="item-to-be-added"] {
                    border-color: $COLOR_V3_RED;
                }
            }

            .item.invalid-maxlength {
                border: 1px solid $COLOR_V3_RED;
            }
        }

        choose-a-date {
            &.ng-invalid select {
                border-color: $COLOR_V3_RED;
            }

            &.ng-invalid-max-date .maxdate-error {
                display: block;
                color: $COLOR_V3_RED;
                clear: left;
            }
        }

        .selectize-input.ng-invalid {
            border-color: $COLOR_V3_RED;
        }

        img.avatar.empty,
        &.ng-invalid careers-avatar.empty {
            border-color: $COLOR_V3_RED !important;
        }

        input-const-autosuggest.ng-invalid .selectize-input {
            border-color: $COLOR_V3_RED;
        }

        date-with-fallback.ng-invalid input {
            border-color: $COLOR_V3_RED;
        }

        select-with-other.ng-invalid select {
            border-color: $COLOR_V3_RED;
        }

        input[type="button"].ng-invalid-required {
            background: $COLOR_V3_RED !important;
        }

        write-text-about.ng-invalid-required textarea {
            border-color: $COLOR_V3_RED;
        }

        write-text-about {
            &.ng-invalid-language textarea,
            &.ng-invalid-minlength textarea,
            &.ng-invalid-maxlength textarea {
                border-color: $COLOR_V3_RED;
            }
        }
    }

    // Custom components

    .multi-select,
    .selectize-control:not(.wide) {
        font-size: $inputFontSize;
        width: $selectWidth;
    }

    select:not(.wide):not(.half):not(.country):not(.transcript-type-select) {
        width: $selectWidth;
        max-width: 100%;
    }

    .react-select:not(.wide):not(.country) {
        width: $selectWidth;
    }

    select {
        padding-left: $SMARTLY_FORM_MARGIN_DEFAULT;
    }

    // make this the same size as selects so at least it lines up with something
    .front-royal-date-picker input {
        width: $selectWidth;
    }

    .multi-select .item,
    .add-an-item .item,
    .selectize-option {
        width: 100%;
        background-color: $COLOR_V3_BEIGE_LIGHTER;

        &:after {
            clear: both;
        }
    }

    .multi-select .item,
    .selectize-option {
        margin-top: 5px;
        border-radius: 4px;

        span {
            margin-left: 2px;
        }
    }

    .add-an-item,
    .multi-select {
        margin-bottom: $SMARTLY_FORM_MARGIN_DEFAULT;
        .item {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: $COLOR_V3_BLACK;
            span {
                vertical-align: middle;
            }
        }
        .empty-item {
            border: 3px dotted $COLOR_V3_BEIGE_LIGHT;
            margin-top: 5px;
        }
    }

    // Selectize General

    .dropdown-template-container {
        display: inline;
        width: 100%;
    }

    .selectize-dropdown {
        width: 100%;
        .option-icon,
        .option {
            margin-top: 2px;

            strong {
                background-color: $selectize-color-highlight;
            }
        }
    }

    .selectize-dropdown-content {
        font-size: 14px;
    }

    .selectize-input {
        .item {
            font-size: 14px;
        }

        input {
            height: auto !important;
        }
    }

    // typeahead completion
    @include set-selectize-general();

    // Icons, Sub-Text, etc

    .img-icon-container {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin: 2px;

        // catch click events outside of the button
        &:after {
            content: "";
            position: absolute;
            top: -10px;
            left: -10px;
            width: calc(100% + 20px);
            height: calc(100% + 20px);
        }

        &.arrow-up:after {
            left: -20px;
        }

        &.arrow-down:after {
            left: 0px;
        }

        &[disabled] {
            cursor: auto;
            opacity: 0.5;
        }
    }

    .currency-container {
        .currency {
            display: inline-block;
            margin: 0;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            background-color: $COLOR_V3_BEIGE_MIDDARK;
            text-align: center;
        }
        input[type="number"] {
            width: calc(280px - 48px);
        }
    }

    // add button container
    .add-container {
        margin-top: $SMARTLY_FORM_MARGIN_DEFAULT * 2;
    }

    //---------------------------
    // File Upload
    //---------------------------

    .upload-button {
        display: inline-block;
        width: 100px;

        @media (max-width: $screen-xs-max) {
            display: block;
            width: 100%;
        }
    }

    .file-info-container {
        position: relative;
        display: inline-block;

        &:not(.transcript-container) {
            width: calc(100% - 120px);
        }
        float: right;

        .download {
            display: block;
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 12px;
            color: $COLOR_V3_BLUE;
            cursor: pointer;
        }

        .file-name {
            display: block;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 12px;
            color: $COLOR_V3_BLACK;
        }

        .uploaded-at {
            display: block;
            font-weight: $FONT_WEIGHT_NORMAL;
            font-size: 12px;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
        }

        front-royal-spinner .wrapper {
            margin-top: 10px;
        }

        @media (max-width: $screen-xs-max) {
            margin-top: 5px;
            display: block;
        }

        .img-icon-container.trashcan {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
    }

    .file-error-message {
        margin: 0.5em 0;
        color: $COLOR_V3_CORAL;
        font-size: 14px;
        font-style: $FONT_STYLE_ITALIC;
    }

    .multiple-files {
        position: relative;
        padding-bottom: 10px;
    }

    .file-remove {
        float: right;
    }

    .upload-wrapper {
        padding: 0;
    }

    .no-padding-label {
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 0px !important;
    }

    //---------------------------
    // Date
    //---------------------------
    date-with-fallback {
        padding-left: 0px;
        padding-right: 0px;
    }

    .privacy-notice {
        font-size: 12px;
        color: $COLOR_V3_GREY;
        margin: 10px 0;

        a {
            color: $COLOR_V3_GREY_DARKER;
            white-space: nowrap;
        }
    }

    //---------------------------
    // Form
    //---------------------------

    .error-message {
        color: $COLOR_V3_RED;
        font-size: 0.8em;
    }
}
