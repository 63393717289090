@mixin remove-outlines {
    &:focus,
    a:focus & {
        outline: 0 !important;
    }
    &:hover,
    a:hover & {
        outline: 0 !important;
    }
    &:active,
    a:active & {
        outline: 0 !important;
    }
}

@mixin footer-buttons-animation() {
    animation: bounceInUp 1s ease forwards;

    &.animation-removed {
        animation: none;
    }
}

// This animation is designed for the launch-bot-button in mobile. When the continue button is hidden, the launch-bot-button
// is at the bottom of the screen. Whe the continue button bounces up into place, we want the launch-bot-button to bounce
// up with it and land just above the continue button.
@keyframes shadowContinueButton {
    0% {
        transform: translateY(0px);
    }

    // delay the start of the motion so that it lines up with
    // the continue button, which is animating from really far away
    30% {
        transform: translateY(0px);
    }

    60% {
        transform: translateY(-$CONTINUE_BUTTON_HEIGHT - 30px);
    }

    80% {
        transform: translateY(-$CONTINUE_BUTTON_HEIGHT + 10px);
    }

    100% {
        transform: translateY(-$CONTINUE_BUTTON_HEIGHT);
    }
}

// This animation handles the case where the launch-bot-button starts at the end of the shadowContinueButton animation,
// and the continue button gets removed. As the continue button bounces down out of the screen, we want the
// launch-bot-button to slide back into it's place at the bottom of the screen.
@keyframes shadowedContinueButtonRemoved {
    0% {
        // this has to start from wherever shadowContinueButton ended
        transform: translateY(-$CONTINUE_BUTTON_HEIGHT);
    }

    30% {
        transform: translateY(30px);
    }

    50% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(0px);
    }
}

// This animation handles the case where the launch-bot-button starts at the end of the shadowContinueButton animation,
// and then the continue button and the launch-bot-button both get removed at the same time. We want the launch-but-button
// to bounce down all the way out of the screen.
@keyframes bounceOutDownWithContinueButton {
    0% {
        // this has to start from wherever shadowContinueButton ended
        transform: translateY(-$CONTINUE_BUTTON_HEIGHT);
    }

    20% {
        opacity: 1;
        transform: translateY(-$CONTINUE_BUTTON_HEIGHT - 20px);
    }

    100% {
        opacity: 0;
        transform: translateY(2000px);
    }
}
