/*
    This will be used for most buttons throughout the site.
    It starts from the basic bootstrap button classes, and then flattens
    the button out.

    Adding an additional color class (i.e. 'purple') will add colors
    for all of the different states

    If this button is placed inside of an <a> tag, then its
    hover, focus and active styles will activate when they are
    activate on the containing <a> tag
*/

/*
    Note that there are some slight differences between
        <button class="flat"> and <button class="btn flat">
    because the "btn" class will win in the few places where
    they differ, like the 'display' value.

    In general, I would suggest not using the .btn class along
    with the flat class.  Then you can know you just have to look here.
*/
button.flat,
input.btn.flat {
    // btn
    display: inline-block;
    margin-bottom: 0; // For input.btn
    font-weight: $btn-font-weight;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    @include button-size($padding-large-vertical, $padding-large-horizontal, 16px, $line-height-large, 4px);
    @include user-select(none);
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    text-shadow: none;
    white-space: nowrap;
    outline: 0;
    text-indent: 0;
    font-style: normal;
    text-decoration: none;
    background: none;
    background-repeat: no-repeat;
    text-transform: capitalize; // FIXME: it would be nicer if this was only there when we wanted it.  But too scary to change now
    border: 0px;

    @include flat-button-text-color($COLOR_V3_WHITE);

    &,
    &:active,
    &.active {
        &:focus {
            @include tab-focus();
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
        outline: 0;
    }

    &:active,
    &.active {
        outline: 0;
        background-image: none;
        @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        pointer-events: none; // Future-proof disabling of clicks
        @include opacity(0.65);
        @include box-shadow(none);
    }

    &.pull-right {
        margin-left: 8px;
    }

    &.pull-left {
        margin-right: 8px;
    }

    //------------------------------
    // Button States
    //------------------------------

    .no-touchevents &:focus,
    .no-touchevents a:focus & {
        box-shadow: none;
        background-color: transparent;
        background-repeat: no-repeat;
        outline: 0 !important;
    }
    .no-touchevents &:hover,
    .no-touchevents a:hover & {
        box-shadow: none;
        background: none;
        background-repeat: no-repeat;
        outline: 0 !important;
    }
    .no-touchevents &:active,
    .no-touchevents a:active &,
    .touchevents &:active,
    .touchevents a:active & {
        box-shadow: none;
        background: none;
        background-repeat: no-repeat;
        outline: 0 !important;
    }

    //------------------------------
    // Coloring
    //------------------------------

    &.purple {
        @include flat-button-color($COLOR_V3_PURPLE, $COLOR_V3_PURPLE_DARK);
    }

    &.green {
        @include flat-button-color($COLOR_V3_GREEN, $COLOR_V3_GREEN_DARK);
    }

    &.turquoise {
        @include flat-button-color($COLOR_V3_TURQUOISE, $COLOR_V3_TURQUOISE_DARK);
    }

    &.yellow {
        @include flat-button-color($COLOR_V3_YELLOW, $COLOR_V3_YELLOW_DARK, lighten($COLOR_V3_YELLOW, 10%));
    }

    &.coral {
        @include flat-button-color($COLOR_V3_CORAL, $COLOR_V3_CORAL_DARK);
    }

    &.coral.hollow {
        @include hollow-button-color($COLOR_V3_CORAL, 1px);
    }

    &.blue {
        @include flat-button-color($COLOR_V3_BLUE, $COLOR_V3_BLUE_DARK);
    }

    &.greyblue {
        @include flat-button-color($COLOR_V3_MAP_BLUE_DARK, $COLOR_V3_MAP_BLUE_MID);
    }

    &.blue.hollow {
        @include hollow-button-color($COLOR_V3_BLUE, 1px);
        @include flat-button-text-color($COLOR_V3_BLUE, $COLOR_V3_WHITE);
    }

    &.blue.no-active {
        @include flat-button-color($COLOR_V3_BLUE, $COLOR_V3_BLUE);
    }

    &.coral.no-active {
        @include flat-button-color($COLOR_V3_CORAL, $COLOR_V3_CORAL);
    }

    &.darkblue {
        @include flat-button-color($COLOR_V3_BLUE_DARK);
    }

    &.completionblue {
        @include flat-button-color($COLOR_V3_BLUE_COMPLETION);
    }

    &.darkred {
        @include flat-button-color($COLOR_V3_RED_DARK);
    }

    &.red {
        @include flat-button-color($COLOR_V3_RED);
    }

    &.white {
        @include flat-button-color($COLOR_V3_WHITE, $COLOR_V3_BEIGE_LIGHTEST, $COLOR_V3_BEIGE_LIGHTEST);
    }

    &.beigelightest-with-coral-text {
        @include flat-button-color($COLOR_V3_BEIGE_LIGHTEST);
        @include flat-button-text-color($COLOR_V3_CORAL);
    }

    &.orange {
        @include flat-button-color($COLOR_V3_ORANGE);
    }

    &.grey {
        @include flat-button-color($COLOR_V3_GREY);
    }

    &.light {
        @include flat-button-color($COLOR_V3_MAP_BLUE_LIGHT, $COLOR_V3_MAP_BLUE_LIGHT, $COLOR_V3_MAP_BLUE_MID);
        color: $COLOR_V3_MAP_BLUE_MID;
    }

    &.beigedark {
        @include flat-button-color($COLOR_V3_BEIGE_DARK);
    }

    &.beigemiddark {
        @include flat-button-color($COLOR_V3_BEIGE_MIDDARK);
        color: $COLOR_V3_GREY_DARKER;
    }

    &.beigedarker {
        @include flat-button-color($COLOR_V3_BEIGE_DARKER);
    }

    &.beigedarkest {
        @include flat-button-color($COLOR_V3_BEIGE_DARKEST, $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK, $COLOR_V3_BEIGE_DARK);
    }

    &.beigebeyonddark {
        @include flat-button-color($COLOR_V3_BEIGE_BEYOND_BEYOND_DARK, null, $COLOR_V3_BEIGE_BEYOND_DARK);
    }

    &.hollow.darkred {
        @include hollow-button-color($COLOR_V3_RED_DARK);
    }

    &.hollow.white {
        @include hollow-button-color($COLOR_V3_WHITE, 2px);
    }

    &.hollow.white.thin {
        @include hollow-button-color($COLOR_V3_WHITE, 1px, $COLOR_V3_CORAL);
    }

    &.hollow.beigedark {
        @include hollow-button-color($COLOR_V3_BEIGE_DARK, 3px);
        @include flat-button-text-color($COLOR_V3_BEIGE_DARK);
    }

    &.hollow.beigedark-with-coral-text {
        @include hollow-button-color($COLOR_V3_BEIGE_DARK, 3px);
        @include flat-button-text-color($COLOR_V3_CORAL);
    }

    &.hollow.beigemiddark {
        @include hollow-button-color($COLOR_V3_BEIGE_MIDDARK, 3px);

        &.medium {
            @include hollow-button-color($COLOR_V3_BEIGE_MIDDARK, 2px);
        }

        &.thin {
            @include hollow-button-color($COLOR_V3_BEIGE_MIDDARK, 1px);
        }
    }

    &.hollow.beigebeyonddark {
        @include hollow-button-color($COLOR_V3_BEIGE_BEYOND_DARK, 3px);

        &.thin {
            @include hollow-button-color($COLOR_V3_BEIGE_BEYOND_DARK, 1px);
        }
    }

    &.white-and-beige {
        @include flat-button-color($COLOR_V3_WHITE, $COLOR_V3_BEIGE_DARK, $COLOR_V3_BEIGE_MIDDARK);
        @include flat-button-text-color($COLOR_V3_BEIGE_DARKER, $COLOR_V3_WHITE);

        &.outline {
            @include flat-outline(2px, $COLOR_V3_BEIGE_MIDDARK, true);
        }
    }

    &.white-and-blue {
        @include flat-button-color($COLOR_V3_WHITE, darken($COLOR_V3_BLUE, 10%), $COLOR_V3_BLUE);
        @include flat-button-text-color($COLOR_V3_BLUE, $COLOR_V3_WHITE);
    }

    &.white-and-coral {
        @include flat-button-color($COLOR_V3_WHITE, darken($COLOR_V3_CORAL, 10%), $COLOR_V3_CORAL);
        @include flat-button-text-color($COLOR_V3_CORAL, $COLOR_V3_WHITE);
    }

    &.outline {
        @include flat-outline(2px, #ffffff);
    }

    &.outline-blue {
        @include flat-outline(1px, $COLOR_V3_BLUE_DARK);

        &:hover {
            background: $COLOR_V3_BLUE_DARK;
        }
    }

    // sometimes, we have outlined buttons and not-outline buttons
    // next to each other (see filters in library).  In those cases,
    // a transparent outline helps us keep the size the same
    &.transparent-outline {
        @include flat-outline(2px, transparent);
    }

    //------------------------------
    // Round buttons
    //------------------------------

    &.round {
        border-radius: 50px;
    }

    //------------------------------
    // Sizing Modifiers
    //------------------------------

    &.skinny {
        width: 140px;
        min-height: 32px;
        padding: 0px;
        margin: 2px !important;
        font-size: 16px;
    }

    &.wide {
        width: 100%;
    }

    &.auto {
        width: auto;
        min-width: 0 !important;
    }

    &.small {
        padding: 0 6px;
        line-height: 20px;
        font-size: 12px;
    }
}
