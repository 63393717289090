@import "oreo/global/colors_v3";

@keyframes bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

@keyframes hide-then-show {
    0% {
        opacity: 0;
    }

    99% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.front-royal-spinner {
    display: block;
    margin: 100px auto 0;
    width: 70px;
    text-align: center;

    &.inline {
        display: inline;
    }

    &.short {
        margin: 30px auto;
    }

    // move spinner to the middle of the screen
    &.fixed {
        position: fixed;
        margin: 0px auto 0;
        height: 100%;
        width: 100%;
        text-align: center;
        transform: translateY(40%);
    }

    &.centered {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 0px;
        transform: translateX(-50%) translateY(-50%);
    }

    .wrapper {
        animation-name: hide-then-show;
        animation-duration: 0.5s;
    }

    &.no-delay {
        .wrapper {
            animation-name: none;
        }
    }

    &.delay-2500 {
        .wrapper {
            animation-duration: 2.5s;
        }
    }

    &.no-top-margin {
        margin-top: 0px;
    }

    &.static-height {
        height: 18px;
    }
}

.front-royal-spinner .wrapper div {
    width: 18px;
    height: 18px;
    background-color: $COLOR_V3_CORAL;
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    animation-fill-mode: both;

    body.bg-turquoise & {
        background-color: $COLOR_V3_TURQUOISE;
    }

    body.bg-purple & {
        background-color: $COLOR_V3_PURPLE;
    }

    body.bg-blue & {
        background-color: $COLOR_V3_BLUE;
    }

    body.valar:not(.bg-turquoise):not(.bg-purple):not(.bg-blue) & {
        background-color: $COLOR_V3_BLUE;
    }
}

// used in dynamic_landing_page
.front-royal-spinner.blue {
    .wrapper div {
        background-color: $COLOR_V3_BLUE;
    }
}

.front-royal-spinner.white {
    .wrapper div {
        background-color: $COLOR_V3_WHITE;
    }
}

.front-royal-spinner.force-white {
    .wrapper div {
        background-color: $COLOR_V3_WHITE !important;
    }
}

body.valar .front-royal-spinner.force-brand {
    .wrapper div {
        background-color: $COLOR_V3_BLUE !important;
    }
}

.front-royal-spinner.force-brand {
    .wrapper div {
        background-color: $COLOR_V3_CORAL !important;
    }
}

body.valar .front-royal-spinner.force-coral {
    .wrapper div {
        background-color: $COLOR_V3_CORAL !important;
    }
}

.front-royal-spinner .wrapper .bounce1 {
    animation-delay: -0.32s;
}

.front-royal-spinner .wrapper .bounce2 {
    animation-delay: -0.16s;
}
