$MODAL_Z_INDEX: 9999; // should sit on top of everything short of any modal overrides

$SERVER_ERROR_MODAL_Z_INDEX: $MODAL_Z_INDEX + 1; // on top of the preventSubmit modal
$FRONT_ROYAL_FOOTER_Z_INDEX: $MODAL_Z_INDEX - 2; // underneath the modal
$COOKIE_BANNER_Z_INDEX: $MODAL_Z_INDEX; // same level as modals, but below errors

$APP_HEADER_Z_INDEX: 3;
$APP_MAIN_CONTAINER_Z_INDEX: 1;

$EDIT_FRAME_LIST_ACTIONS_Z_INDEX: $FRONT_ROYAL_FOOTER_Z_INDEX + 1; // above the footer, behind the modal
