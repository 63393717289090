organization-autocomplete {
    input {
        width: 100%;
        &.form-control,
        &.form-control:focus {
            box-shadow: none;
        }
    }

    .selectize-dropdown.loading-dropdown {
        width: 100%;
        margin-top: 0px;
        min-height: 50px;

        // on the outer sessions/signup pages, we need this margin to line up properly
        .sessions &,
        .onboarding__wrapper & {
            margin-top: 10px;
            width: 100%;
        }

        @media (min-width: $screen-sm-min) {
            width: 400px;
        }

        .loading-title {
            margin: 18px;
            text-align: center;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
        }

        .loading {
            margin: 5px;
        }
    }

    [uib-typeahead-popup] {
        .option-icon-default {
            background-image: url("~vectors/buildings.svg");
        }

        .uib-typeahead-match {
            cursor: pointer;
            &:hover {
                background-color: $COLOR_V3_BEIGE_LIGHTEST;
            }
        }
    }
}
