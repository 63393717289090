careers-avatar {
    display: block;
    border-style: solid;
    border-color: $COLOR_V3_WHITE;
    border-width: 1px;
    border-radius: 99999px; //circular at any size
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $COLOR_V3_WHITE;

    &.no-outline {
        box-shadow: none;
    }

    &.unlock-top {
        top: auto !important;
    }
}
