input-const-autosuggest {
    .selectize-control.single {
        position: relative;
        z-index: 1;
        height: 48px;

        .selectize-input {
            padding-right: 30px;

            @media (min-width: $screen-sm-min) {
                padding-right: 100px;
            }

            &::after {
                display: none;
            }
        }
    }

    .remaining {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        text-align: right;
        padding-right: 10px;
        z-index: 2;

        &.invalid {
            color: $COLOR_V3_RED;
        }
    }
}
