@mixin set-selectize-general() {
    [uib-typeahead-popup] {
        .selectize-dropdown {
            width: 100%;

            @media (min-width: $screen-sm-min) {
                width: 400px;
            }

            .selectize-dropdown-content {
                padding: 0px;

                .uib-typeahead-match {
                    position: relative;

                    .option-icon {
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        top: 0px;
                        left: 0px;
                        margin: 10px;
                        padding: 0px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    .option {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 0;
                        display: block;
                        line-height: 40px;
                        padding: 5px;
                        padding-left: 10px;

                        &.organization {
                            padding-left: 50px;
                        }
                    }
                }
            }
        }
    }
}

@mixin set-selectize-height($screenSize, $height, $borderWidth, $lineHeight: 18px) {
    $padding: ($height - $lineHeight - 2 * $borderWidth) * 0.5;

    @media (min-width: $screenSize) {
        .selectize-input,
        .selectize-control.multi .selectize-input.has-items {
            min-height: $height;
            height: auto;
            padding-top: $padding;
            padding-bottom: $padding;
            border-width: $borderWidth;

            > input {
                // this must take precedence over
                // set-inputs-height, in case you are using both
                height: auto;
            }
        }

        // the selected items must have no vertical margins or padding so
        // that the input does not grow vertically when there are items selected
        .selectize-control.multi .selectize-input .item {
            margin-top: 0px;
            margin-bottom: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    // make sure this guy keeps his height even when the selectize inside of it is not there
    locale-pack-selectize {
        display: inline-block;
        height: $height;
        width: 100%;
    }
}

@mixin set-selectize-max-width($maxWidth) {
    .selectize-input,
    .selectize-control.multi .selectize-input.has-items {
        max-width: $maxWidth;
    }
}

@mixin set-inputs-height($screenSize, $height, $borderWidth, $fontSize, $lineHeight: 18px) {
    @include set-selectize-height($screenSize, $height, $borderWidth, $lineHeight);
    @include set-selectize-font-size($fontSize, $fontSize, $lineHeight);

    $padding: ($height - $lineHeight - 2 * $borderWidth) * 0.5;

    @media (min-width: $screenSize) {
        input,
        .styled-as-input {
            height: $height;
            border-width: $borderWidth;
            @include set-xs-and-sm-font-size($fontSize, $fontSize);
            line-height: $height;
        }
    }
}

@mixin selectize-use-little-triangle-after() {
    .selectize-control .selectize-input:after,
    .selectize-control.single .selectize-input:after,
    .styled-as-single-dropdown:after {
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -3px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #808080 transparent transparent transparent;

        // rtl-language overrides
        html[dir="rtl"] & {
            right: inherit;
            left: 10px;
        }
    }
    .selectize-control .selectize-input.dropdown-active:after {
        margin-top: -4px;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #808080 transparent;
    }
    .selectize-control.rtl .selectize-input:after {
        left: 15px;
        right: auto;
    }
}

@mixin set-xs-and-sm-font-size($xsFontSize, $smFontSize) {
    font-size: $xsFontSize;
    @media (min-width: $screen-sm) {
        font-size: $smFontSize;
    }
}

@mixin set-selectize-font-size($xsFontSize, $smFontSize, $lineHeight) {
    .selectize-dropdown,
    .selectize-input,
    .selectize-input input {
        @include set-xs-and-sm-font-size($xsFontSize, $smFontSize);
        line-height: $lineHeight;
    }

    .selectize-input > .item {
        @include set-xs-and-sm-font-size($xsFontSize, $smFontSize);
    }

    .selectize-dropdown-content .option {
        @include set-xs-and-sm-font-size($xsFontSize, $smFontSize);
    }
}

// If we don't do this on sessions screens then the placeholder
// text is cut off.  If we DO do this on send-frames-to-lesson,
// then placeholder text is cutoff.  Go figure.
@mixin selectize-force-auto-width() {
    .selectize-input > input {
        width: auto !important;
    }
}

@mixin set-inputs-borders($color, $radius) {
    .selectize-input,
    input,
    .styled-as-input {
        border-color: $color;
        border-radius: $radius;
        border-style: solid;
    }
}

@mixin set-inputs-border-radius($radius) {
    .selectize-input {
        border-radius: $radius;
    }

    input {
        border-radius: $radius;
    }
}

@mixin set-inputs-font-color($primaryColor, $secondaryColor) {
    .selectize-input > * {
        color: $primaryColor;
    }

    .selectize-dropdown-content {
        .option {
            color: $secondaryColor;
        }

        .option.selected {
            color: $primaryColor;
        }
    }

    input,
    .styled-as-input {
        color: $primaryColor;
    }

    input::placeholder {
        color: $secondaryColor;
    }
}

.selectize-triangle-after {
    @include selectize-use-little-triangle-after();
}

.selectize-all-40h {
    @include set-selectize-height(0, 40px, 1px);
}

.selectize-36h {
    @include set-selectize-height($screen-xs, 36px, 1px);
}

.selectize-31h {
    @include set-selectize-height($screen-xs, 31px, 1px);
}

.selectize-font-13 {
    @include set-selectize-font-size(13px, 13px, 18px);
}

.inputs-31h {
    @include set-inputs-height($screen-xs, 31px, 1px, 15px);
}

.beige-input-borders {
    @include set-inputs-borders($COLOR_V3_BEIGE_BEYOND_DARK, 4px);
}

.inputs-eggplant-fonts {
    @include set-inputs-font-color($COLOR_V3_EGGPLANT, $COLOR_V3_BEIGE_DARKEST);
}

.reset-form-states {
    input,
    input:focus {
        outline: none;
    }
}

.styled-as-single-dropdown {
    position: relative;
}

.selectize-max-width-in-table {
    table {
        @include set-selectize-max-width(250px);
    }
}
