.front-royal-form-container {
    $selectizePaddingSidebar: 12px;
    $inputFontSizeSidebar: 12px;
    $checkboxSize: 13px;
    $checkboxLabelLeftMarginSidebar: 5px;
    $inputFontSize: 12px;

    // special styles for the sidebar of page-with-left-nav-and-main-box
    &.responsive-nav,
    .responsive-nav & {
        @include front-royal-form-sizes(
            $inputHeight: 36px,
            $selectizePadding: 12px,
            $inputFontSize: $inputFontSize,
            $checkboxSize: 13px,
            $checkboxLabelLeftMargin: 5px
        );
        label {
            font-size: 14px;
        }

        .form-group {
            padding: 0px 15px;
            margin-top: 13px;
            margin-bottom: 7px;

            &.border,
            &.row:not(.no-border) {
                padding-bottom: 13px;
            }
        }

        .multi-select,
        .selectize-control:not(.wide),
        select.wide {
            font-size: 14px;
            width: 100%;
            margin-bottom: 0px;
        }

        .multi-select.has-selection {
            // Just here in the sidebar, we turn the select box color
            // darker when something is selected.  This is because it felt
            // like this made sense specifically in the case of selecting
            // filters, which is what we expect the sidebar to be used for.
            // In that case, the dark color indicates that you have made an
            // option in an optional input.
            // FIXME: implement for selectizeMode?
            select {
                color: $COLOR_V3_BLACK !important; // important! because multi-select defines this as important!
            }
        }

        select {
            margin-top: 0px;
        }

        .multi-select .item {
            line-height: $inputFontSize;
            span {
                vertical-align: initial;
            }
        }

        .multi-select .item,
        .selectize-option {
            font-weight: $FONT_WEIGHT_MEDIUM;

            .img-icon-container[name="remove"] {
                color: $COLOR_V3_BLUE_DARK;
            }
        }

        .multi-select .item,
        .add-an-item .item,
        .selectize-option {
            background-color: $COLOR_V3_BLUE_LIGHTER;
        }

        // use unstyled-button and sub-text too
        .plain-text-button {
            display: block;
            text-align: center;
            text-decoration: underline;
            width: 100%;
            margin-top: 10px;

            &:not([disabled]):hover {
                text-decoration: none;
            }

            &[disabled] {
                opacity: 0.5;
            }
        }
    }
}
