$ANSWER_VALIDATION_DURATION: 1.5s;
$EASE_OUT_BOUNCE: cubic-bezier(0.07, 1.65, 0.815, 0.77);

// http://matthewlein.com/ceaser/

@keyframes correct_button_disabled {
    0%,
    85% {
        background-color: $COLOR_ANSWER_CORRECT;
        border-color: $COLOR_ANSWER_CORRECT;
    }

    100% {
        border-color: $COLOR_V3_TURQUOISE_DARK;
        background-color: $COLOR_V3_TURQUOISE_DARK;
    }
}

// Enter Animations

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-in {
    animation-name: fade-in;
    animation-duration: 0.5s;

    bot & {
        animation-duration: 0s;
    }
}

@keyframes fade-in-down-subtle {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fade-in-up-subtle {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fade-in-up-subtle-margin {
    0% {
        opacity: 0;
        margin-top: 20px;
    }

    100% {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes fade-in-right-subtle {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes pop-in {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    85% {
        transform: scale(1.05);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes pop-in-big {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    75% {
        transform: scale(1.2);
    }

    90% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slide-in-left-sidebar {
    0% {
        transform: translate(-100px, 0px);
        opacity: 0;
    }
    1% {
        opacity: 1;
    }

    100% {
        transform: translate(0px, 0px);
        opacity: 1;
    }
}

@keyframes slide-in-right-sidebar {
    0% {
        transform: translate(100px, 0px);
        opacity: 0;
    }
    1% {
        opacity: 1;
    }

    100% {
        transform: translate(0px, 0px);
        opacity: 1;
    }
}

@keyframes header-box-slide-right {
    0% {
        opacity: 0;
        background-position: right -20px top;
    }

    100% {
        opacity: 1;
        background-position: right -50px top;
    }
}

@keyframes header-box-slide-left {
    0% {
        opacity: 0;
        background-position: left -20px top;
    }

    100% {
        opacity: 1;
        background-position: left -50px top;
    }
}

@keyframes bounceInUp {
    0% {
        opacity: 0;
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        transform: translateY(-30px);
    }

    80% {
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounceThumbsUp {
    0%,
    20% {
        opacity: 0;
        transform: translateY(100px);
    }

    60% {
        opacity: 1;
        transform: translateY(-15px);
    }

    80% {
        opacity: 1;
        transform: translateY(10px);
    }

    90% {
        opacity: 1;
        transform: translateY(5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounceInDown {
    0% {
        opacity: 0;
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        transform: translateY(30px);
    }

    80% {
        opacity: 1;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounceOutUp {
    0% {
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        transform: translateY(-2000px);
    }
}

@keyframes bounceOutDown {
    0% {
        transform: translateY(0);
    }

    20% {
        opacity: 1;
        transform: translateY(-20px);
    }

    100% {
        opacity: 0;
        transform: translateY(2000px);
    }
}

// Attention-getting Animations

@keyframes tada {
    from {
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-5px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(5px);
    }
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
    }

    80% {
        transform: scale(1.2);
    }
}

@keyframes subtle-pulse {
    0%,
    100% {
        transform: scale(1);
    }

    70% {
        transform: scale(1.1);
    }
}

@keyframes subtle-pulse-repeat {
    0%,
    15%,
    100% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.1);
    }
}

@keyframes lesson-progress-flip {
    0% {
        transform: rotateX(90deg);
    }

    80% {
        transform: rotateX(-10deg);
    }

    90% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

@keyframes pulse-then-fade-out {
    // Pulse in initially...
    0%,
    15% {
        transform: scale(1);
    }

    12% {
        transform: scale(1.2);
    }

    // ...then fade out
    0%,
    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes flash-blue-border {
    0% {
        border: 0px solid transparent;
    }

    40% {
        border: 3px solid $COLOR_V3_BLUE;
    }

    90% {
        border: 3px solid $COLOR_V3_BLUE;
    }

    100% {
        border: 0px solid transparent;
    }
}

.flash-blue-border {
    animation-name: flash-blue-border;
    animation-duration: 2s;

    bot & {
        animation-duration: 0s;
    }
}
