tel-input,
.mock-tel-input {
    padding: 0;
    cursor: pointer;

    .inner-tel-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    $countryCodeWidth: 80px;
    $extensionWidth: 80px;

    &.wide {
        padding: 0;
        width: 100%;
    }

    input {
        unicode-bidi: embed;
        direction: ltr;

        html[dir="rtl"] & {
            text-align: right;
        }
    }

    input:not(.extension) {
        // make it fit against the input field
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    input.local-number {
        width: calc(100% - #{$countryCodeWidth});
        border-radius: 4px;
        outline: none;
        appearance: none;
        border: 1px solid #e6e6e6;
        border-left: none !important;
        padding: 8px;
    }

    input.extension {
        width: $extensionWidth;
        @media (max-width: $screen-xs-max) {
            width: 100%;
            display: block;
        }
    }

    .include-extension {
        input.local-number {
            @media (max-width: $screen-xs-max) {
                width: calc(100% - #{$extensionWidth});
                margin-bottom: $SMARTLY_FORM_MARGIN_DEFAULT;
            }

            @media (min-width: $screen-sm-min) {
                width: calc(100% - #{$extensionWidth + $countryCodeWidth + 10px});
                border-left: none;
            }
        }

        .selected-prefix {
            @media (max-width: $screen-xs-max) {
                transform: none;
                top: 15px;
            }
        }
    }

    .selected-prefix {
        position: absolute;
        left: 8px;
        top: 50%;
        width: $countryCodeWidth - 40px; // remove width to account for padding and dropdown arrow on right
        display: block;
        transform: translateY(-50%);
        color: $LOGIN_REGISTER_TEXT_GRAY;
        text-align: center;
        pointer-events: none;
        overflow: visible;
        white-space: nowrap;
    }

    .tel-select {
        width: $countryCodeWidth !important;
        display: inline-block !important;
        float: left;
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin-bottom: 0px;

        appearance: none;
        background: url(~vectors/chevron_down_beige_thin.svg) no-repeat right 12px center;
        background-color: #fafafa;

        &,
        &:focus {
            color: rgba(#fafafa, 0) !important;
        }

        option {
            color: $COLOR_V3_BLACK !important;
        }

        // make it fit against the input field
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;

        &:after {
            right: 15px;
        }

        html[dir="rtl"] & {
            width: auto;
        }

        input {
            width: 100% !important;
            display: inline-block !important;
            position: relative !important;
            float: right;
        }
    }

    .tel-select::-ms-expand {
        display: none;
    }

    /******************************************
    * FORM SUBMISSION VALIDATION HIGHLIGHTING *
    ******************************************/

    .ng-submitted &.ng-invalid {
        .tel-select {
            // If form has been submitted and a valid country code has been selected,
            // but a local number has not been entered in the input field, we need to
            // remove the right border of the country code selectize input and re-add
            // the left border of the local number input field in preparation for the
            // boder validation highlighting. If we don't do this, then the red border
            // highlighting only appears on three sides of the input field.
            &.valid-country-code {
                border-right: none;

                &:not(.valid-local-number) ~ input.local-number {
                    border: 1px solid $COLOR_V3_RED !important;
                }
            }

            // if form has been submitted, but a valid country code has not been selected
            &:not(.valid-country-code) {
                border: 1px solid $COLOR_V3_RED;
            }

            // show red border if form has been submitted and no valid local number is present
            &:not(.valid-local-number) input.local-number {
                border: 1px solid $COLOR_V3_RED;
            }
        }

        // tel input error validation styling
        input.local-number:not(.ng-pristine) {
            border: 1px solid $COLOR_V3_RED !important;
            border-left: none !important;
        }
    }

    /****************************************
    * SESSIONS FORM VALIDATION HIGHLIGHTING *
    ****************************************/

    // NOTE: These sessions styles require the !important override to ensure
    // that they take precedence over the styles specified in sessions.scss.

    .sessions & {
        .tel-select {
            border: 1px solid #e6e6e6;
        }

        .local-number.has-error {
            border: 1px solid $COLOR_V3_RED !important;
            border-left: none !important;
        }

        // everything has been properly filled out
        &.valid-country-code.valid-local-number {
            // remove this border to avoid the left border of the input field and this border
            // from creating an ugly two-border-thick line between the country code dropdown
            // and the input field
            border-right: none !important;

            &,
            & + input.local-number {
                border: 1px solid $COLOR_MARKETING_TURQUOISE !important;
            }
        }

        // an error is present
        &.has-error {
            // remove this border to avoid the left border of the input field and this border
            // from creating an ugly two-border-thick line between the country code dropdown
            // and the input field
            border-right: none !important;

            &,
            & + input.local-number {
                border: 1px solid $COLOR_V3_RED !important;
            }
        }
    }

    &.no-right-padding {
        padding-right: 0px !important;
    }
}
