/*
    We shrink a bunch of things when we render forms in the sidebar on
    the left.

    Since different styles are often dependent on the size of some particular
    thing, it was cleaner to make a mixin to handle that, allowing us to
    set just a few variables and have all the styles dependent on those variables
    change together.

    It may seem totally arbitrary which things are defined here and which are
    defined in front_royal_form or front_royal_form_sidebar.  But there is a
    method to the madness.

    1. By default, define form styles in front_royal_form
    2. If a style is overridden in the sidebar, put that in front_royal_form_sidebar.scss
    3. If two or more styles rely on a single variable, and the RULE that defines
        how they rely on that variable is the same in the default forms and the sidebar,
        then use this mixin.

    Example 1:

        front_royal_form.scss defines the font-size for a label element to
        be 16px. In the sidebar we want that to be 14px.  This is a simple override.
        No need to use this mixin

    Example 2:

        A bunch of things rely on the variable $checkboxSize.  The rules for this apply the
        same in default forms and in the sidebar.  We move the following lines of code
        into this mixin so we don't have to duplicate them in front_royal_form.scss and
        front_royal_form_sidebar.scss:

        input[type=checkbox],
        input[type=radio] {
            width: $checkboxSize;
            height: $checkboxSize;

            &:before {
                width: $checkboxSize;
                height: $checkboxSize;
            }
        }

        .form-group.checkbox-group {

            .checkbox-group-label-wrapper {
                padding-left: $checkboxSize + $checkboxLabelLeftMargin;

                // rtl-language overrides
                html[dir=rtl] & {
                    padding-right: $checkboxSize + $checkboxLabelLeftMargin;
                }
            }

            input {
                margin-right: -$checkboxSize;
            }
        }


*/
@mixin front-royal-form-sizes(
    $inputHeight,
    $inputFontSize,
    $checkboxSize,
    $checkboxLabelLeftMargin,
    $selectizePadding
) {
    input[type="text"],
    input:not([type]),
    input[type="date"],
    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="tel"],
    select {
        height: $inputHeight;
    }

    .react-select {
        input[type="text"] {
            // since react-select has extra padding around the
            // input, this is what it takes to make the who field
            // 48px like the rest of the stuff
            height: $inputHeight - 14px;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        width: $checkboxSize;
        height: $checkboxSize;

        &:before {
            width: $checkboxSize;
            height: $checkboxSize;
        }
    }

    .add-an-item {
        button[name="add-item"] {
            height: $inputHeight;

            @media (max-width: $screen-xs-max) {
                min-width: $inputHeight !important;
                width: $inputHeight !important;
            }
        }
    }

    .multi-select .item,
    .selectize-option {
        padding: $selectizePadding;

        .img-icon-container[name="remove"] {
            right: $selectizePadding;
            font-size: #{$inputFontSize + 2px};

            // rtl-language overrides
            html[dir="rtl"] & {
                right: auto;
                left: $selectizePadding;
                text-align: left;
            }
        }
    }

    .multi-select .item,
    .add-an-item .item,
    .selectize-option {
        font-size: $inputFontSize;
    }

    .multi-select {
        .item {
            height: $inputHeight;
        }
        .empty-item {
            height: $inputHeight;
        }
    }

    .selectize-dropdown {
        .option-icon,
        .option {
            height: $inputHeight;
            padding: $selectizePadding;
        }
    }

    .selectize-input {
        height: $inputHeight;
        padding: $selectizePadding;
    }

    .currency-container {
        .currency {
            line-height: $inputHeight;
            height: $inputHeight;
            width: $inputHeight;
        }
    }

    .form-group.checkbox-group {
        .checkbox-group-label-wrapper {
            padding-left: $checkboxSize + $checkboxLabelLeftMargin;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: $checkboxSize + $checkboxLabelLeftMargin;
            }
        }

        input {
            margin-right: -$checkboxSize;
        }

        &.radio-group {
            label {
                padding-top: 0;
            }

            input {
                margin-right: 0;
                margin-left: -$checkboxSize;
            }
        }
    }
}
