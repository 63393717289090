/*
    This will be used for most buttons throughout the site.
    It starts from the basic bootstrap button classes, and then flattens
    the button out.

    Adding an additional color class (i.e. 'purple') will add colors
    for all of the different states

    If this button is placed inside of an <a> tag, then its
    hover, focus and active styles will activate when they are
    activate on the containing <a> tag
*/

@mixin flat-button-color(
    $flatButtonColor,
    $flatButtonColorDark: darken($flatButtonColor, 10%),
    $flatButtonColorLight: lighten($flatButtonColor, 3%)
) {
    @include flat-button-text-color($COLOR_V3_WHITE);
    background-color: $flatButtonColor;

    .no-touchevents &:focus,
    .no-touchevents a:focus & {
        background-color: $flatButtonColor;
    }
    .no-touchevents &:hover:not(:disabled),
    .no-touchevents a:hover:not(:disabled) & {
        background-color: $flatButtonColorLight;
        //box-shadow: inset 0px 0px 0px 3px darken($flatButtonColor, 10%);
    }
    .no-touchevents &:active,
    .no-touchevents a:active &,
    .touchevents &:active,
    .touchevents a:active & {
        background-color: $flatButtonColorDark;
    }

    @media (max-width: $screen-xs-max) {
        // Make the slab that floats below the button so that when it bounces up into place
        // the right color for the button
        &:after {
            background-color: $flatButtonColor !important;
        }
    }

    &:disabled {
        opacity: 0.75;
    }
}

@mixin hollow-button-color(
    $flatButtonColor,
    $borderWidth: 2px,
    $textColorFilled: $COLOR_V3_WHITE,
    $backgroundColor: transparent
) {
    box-shadow: inset 0px 0px 0px $borderWidth $flatButtonColor;
    background-color: $backgroundColor;
    color: $flatButtonColor;

    .no-touchevents &:focus:not(:active),
    .no-touchevents a:focus:not(:active) & {
        box-shadow: inset 0px 0px 0px $borderWidth $flatButtonColor;
        background-color: $backgroundColor;
        color: $flatButtonColor;
    }
    .no-touchevents &:hover:not(:active),
    .no-touchevents a:hover:not(:active) & {
        box-shadow: none;
        background-color: $flatButtonColor;
        color: $textColorFilled;
    }
    .no-touchevents &:active,
    .no-touchevents a:active &,
    .touchevents &:active,
    .touchevents a:active & {
        box-shadow: none;
        background-color: darken($flatButtonColor, 10%);
        color: $textColorFilled;
    }
}

@mixin flat-button-text-color($textColor, $hoverColor: $textColor, $activeColor: $hoverColor) {
    color: $textColor;

    &:hover,
    &:focus {
        color: $textColor;
    }

    //------------------------------
    // Button States
    //------------------------------

    // Make no changes on hover or focus on touch devices (yes, this really happens.  for info on touch/hover, see https://www.nczonline.net/blog/2012/07/05/ios-has-a-hover-problem/ ...
    // Also make no changes in focus on non-touch devices
    .touchevents &:hover:not(:active),
    .touchevents a:hover:not(:active) &,
    &:focus:not(:active),
    a:focus:not(:active) & {
        color: $textColor;
    }

    .no-touchevents &:hover:not(:active),
    .no-touchevents a:hover:not(:active) & {
        color: $hoverColor;
    }

    &:active,
    a:active & {
        color: $activeColor;
    }
}

@mixin flat-outline(
    $flatOutlineWidth: 2px,
    $flatOutlineColor: #ffffff,
    $hideOnHover: false,
    $hideOnActive: $hideOnHover
) {
    border-left: $flatOutlineWidth;
    border-right: $flatOutlineWidth;
    border-top: $flatOutlineWidth;
    border-bottom: $flatOutlineWidth;
    border-style: solid;
    border-color: $flatOutlineColor;

    @if $hideOnHover {
        .no-touchevents &:hover,
        .no-touchevents a:hover & {
            border-color: transparent;
        }
    }

    @if $hideOnActive {
        &:active,
        a:active & {
            border-color: transparent;
        }
    }
}
