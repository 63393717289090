// App header
$APP_HEADER_HEIGHT: 75px;
$APP_HEADER_HEIGHT_MOBILE: 40px;

// Frame Content / Layout
$MAIN_CONTENT_MIN_WIDTH: 700px;
$FRAME_CONTAINER_SMALL_SCREEN_HORZ_PADDING: 10px;
$FRAME_CONTAINER_LARGE_SCREEN_HORZ_PADDING: 0px;

// Content
$MAIN_IMAGE_MIN_HEIGHT: 20px;
$MAIN_IMAGE_HEIGHT: 275px;
$MAIN_IMAGE_HEIGHT_SMALL_MIN: 200px;
$MAIN_IMAGE_SHORT_HEIGHT: 150px;
$MAIN_IMAGE_SHORT_HEIGHT_SMALL_MIN: 75px;

// Tile Prompts
$TILE_PROMPT_WIDTH: 400px;
$TILE_PROMPT_WIDTH_SMALL_MIN: 275px;
$TILE_PROMPT_HEIGHT: 250px;
$TILE_PROMPT_HEIGHT_SMALL_MIN: 200px;

// Fillable Rect
$FILLABLE_RECT_BIG_ICON_DIAMETER: 30px;
$FILLABLE_RECT_SMALL_ICON_DIAMETER: 16px;

// Footers
$FRONT_ROYAL_FOOTER_LINE_HEIGHT: 25px;
$FRONT_ROYAL_FOOTER_HEIGHT_SMALL: 70px;
$FRONT_ROYAL_FOOTER_HEIGHT_LARGE: $FRONT_ROYAL_FOOTER_LINE_HEIGHT + 40px;

// Slide Message
$SLIDE_MESSAGE_DESKTOP_WIDTH: 272px;

// Continue Button
$CONTINUE_BUTTON_DESKTOP_PADDING: 10px;
$CONTINUE_BUTTON_HEIGHT: 50px;
$CONTINUE_BUTTON_WIDTH: $SLIDE_MESSAGE_DESKTOP_WIDTH - 2 * $CONTINUE_BUTTON_DESKTOP_PADDING;
$CONTINUE_BUTTON_WIDTH_COMPLETION_SCREENS: 550px;
$PREVIOUS_BUTTON_HEIGHT: $CONTINUE_BUTTON_HEIGHT;
$PREVIOUS_BUTTON_WIDTH: 44px;

// Mobile tab switcher at bottom of screen
$APP_MENU_MOBILE_HEIGHT: 55px;

// Editor
$EDIT_FRAME_LIST_SIDEBAR_LEFT_WIDTH: 160px;
$EDIT_FRAME_LIST_SIDEBAR_RIGHT_WIDTH: 320px;

// Responsive additions
$MIN_REQUIRED_HEIGHT: 300px;

// Main box with Nav sizes
$MAIN_BOX_WITH_LEFT_NAV_DESKTOP_NAV_WIDTH: 220px;
$MAIN_BOX_WITH_LEFT_NAV_DESKTOP_BOX_WIDTH: 800px;

// Custom sizes for iPhone devices
// Reference: http://viewportsizes.com/?filter=iphone
$screen-iphone5-max: 320px; // All iphones prior to iphone 5 were 320px wide
$screen-iphone6-min: $screen-iphone5-max + 1;
$screen-iphone6-max: 375px;
$screen-iphone6plus-min: $screen-iphone6-max + 1;
$screen-iphone6plus-max: 414px;

// scrollbar
$WEBKIT_SCROLLBAR_WIDTH: 8px;

$STUDENT_NETWORK_EVENTS_SIDEBAR_WIDTH_DESKTOP: 478px;

@media (max-width: $screen-iphone6-max) {
    @include responsive-invisibility(".hidden-iphone6");
    @include responsive-visibility(".visible-iphone6");
    .visible-iphone6-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: $screen-iphone5-max) {
    @include responsive-invisibility(".hidden-iphone5");
    @include responsive-visibility(".visible-iphone5");
    .visible-iphone5-inline-block {
        display: inline-block !important;
    }
}
