//---------------------------
// V3 Rebrand Colors
//---------------------------

// whole palette (clockwise from 12)

$COLOR_V3_RED: #e7384d;
$COLOR_V3_RED_LIGHT: #ffdbdb;
$COLOR_V3_RED_DARK: #842138;

$COLOR_V3_PURPLE: #9557ec;
$COLOR_V3_PURPLE_NOT_QUITE_AS_DARK: #7e4ac7;
$COLOR_V3_PURPLE_DARK: #513584;
$COLOR_V3_PURPLE_HOVER: #503883;
$COLOR_V3_PURPLE_LIGHT: #d7baff;

$COLOR_V3_BLUE: #4d7aff;

$COLOR_V3_BLUE_CAREERS_LIGHTER: #6e92ff; // used for background in desktop candidate card

// oratner do we need to change this? its referred to in the UI_V3 buttons page
// but doesnt exist in the color palette
$COLOR_V3_BLUE_LIGHT: #b9cafe;
$COLOR_V3_BLUE_LIGHTER: #eff3fe;
$COLOR_V3_BLUE_NOT_SO_LIGHT: #1a58c2;
$COLOR_V3_BLUE_DARK: #194695;
$COLOR_V3_BLUE_DARKEST: #0c2d67;
$COLOR_V3_BLUE_COMPLETION: #1e5479;
$COLOR_V3_BLUE_HOVER: #1c4894;
$COLOR_V3_BLUE_TWITTER: #4099ff;
$COLOR_V3_BLUE_LINKEDIN: #0077b5;
$COLOR_V3_BLUE_FACEBOOK: #4267b2;
$COLOR_V3_BLUE_GOOGLE: #4285f4;
$COLOR_V3_BLUE_WECHAT: #47bb36;

// Hover colors for social links
$COLOR_V3_BLUE_GOOGLE_HOVER: lighten($COLOR_V3_BLUE_GOOGLE, 5%);
$COLOR_V3_BLUE_FACEBOOK_HOVER: lighten($COLOR_V3_BLUE_FACEBOOK, 5%);
$COLOR_V3_BLUE_TWITTER_HOVER: lighten($COLOR_V3_BLUE_TWITTER, 5%);
$COLOR_V3_BLUE_LINKEDIN_HOVER: lighten($COLOR_V3_BLUE_LINKEDIN, 5%);
$COLOR_V3_BLUE_WECHAT_HOVER: lighten($COLOR_V3_BLUE_WECHAT, 5%);

$COLOR_V3_TURQUOISE_LIGHT: #95d6cb;
$COLOR_V3_TURQUOISE: #1dd0b4;
$COLOR_V3_TURQUOISE_DARK: #106e6e;
$COLOR_V3_TURQUOISE_GRASSY_DARK: #147867;
$COLOR_V3_TURQUOISE_HOVER: #155f5e;

$COLOR_V3_GREEN: #0dd037;
$COLOR_V3_GREEN_DARK: #0a7823;
$COLOR_V3_GREEN_LIGHT: #dbffe5;

$COLOR_V3_YELLOW: #ffbe00;
$COLOR_V3_YELLOW_LIGHT: #ffedba;
$COLOR_V3_YELLOW_DARK: #896600;

$COLOR_V3_ORANGE: #ff6357;
$COLOR_V3_ORANGE_DARK: #953529;

$COLOR_V3_CORAL: #ff4d63;
$COLOR_V3_CORAL_DARK: #a52e3b;
$COLOR_V3_CORAL_HOVER: #a12e3d;

$COLOR_V3_WHITE: #ffffff;

$COLOR_V3_DARK_GREY: #8f817d;
$COLOR_V3_GREY_COMPLETION: #d8d8d8;

$COLOR_V3_GREY: #9c9c9c;
$COLOR_V3_GREY_NOT_SO_LIGHT: #9b9b9b;
$COLOR_V3_GREY_LIGHT: #cacaca;
$COLOR_V3_GREY_LIGHTER: #eaeaea;
$COLOR_V3_GREY_DARK: #909090;
$COLOR_V3_GREY_DARKISH: #666665;
$COLOR_V3_GREY_DARKER: #4a4a4a;
$COLOR_V3_GREY_DARK_BLUE: #3e495c;

$COLOR_V3_BETA_GREY: #5c5752;
$COLOR_V3_GREY_SHUTTLE: #59687c;

// background palette
$COLOR_V3_BEIGE_FOR_TEXT: #776f65;
$COLOR_V3_BEIGE_BEYOND_BEYOND_DARK: #847d76;
$COLOR_V3_BEIGE_BEYOND_DARK: #a9a197;
$COLOR_V3_BEIGE_DARKEST: #b5a99e;
$COLOR_V3_BEIGE_DARKER: #c0b8ae;
$COLOR_V3_BEIGE_DARK: #d4ccc3;
$COLOR_V3_BEIGE_MIDDARK: #dfd9d2;
$COLOR_V3_BEIGE_MEDIUM: #eae6e1;
$COLOR_V3_BEIGE: #eeebe8;
$COLOR_V3_BEIGE_LIGHT: #f7f4f0;
$COLOR_V3_BEIGE_LIGHTER: #faf6f0;
$COLOR_V3_BEIGE_LIGHTEST: #f5f3f1;

// Colors from pixelmatters
$COLOR_PIXELMATTERS_GREEN: #54de6f;
$COLOR_PIXELMATTERS_GREEN_DARKER: #4bc463;
$COLOR_PIXELMATTERS_GREY_DARK: #757068;
$COLOR_PIXELMATTERS_GREY_LIGHT: #dfd9d3;

// Convenience Aliases

$COLOR_ANSWER_CORRECT: $COLOR_V3_GREEN;
$COLOR_ANSWER_INCORRECT: $COLOR_V3_YELLOW;
$COLOR_ANSWER_NEUTRAL: $COLOR_V3_BLUE;
$BLANK_UNDERLINE_SELECTED_COLOR: $COLOR_V3_CORAL;
$BLANK_UNDERLINE_UNSELECTED_COLOR: rgb(0, 0, 0);

$FILL_IN_THE_BLANK_UNSELECTED_BOX_SHADOW: inset 0px 0px 8px 0px rgba($BLANK_UNDERLINE_UNSELECTED_COLOR, 0.3);
$FILL_IN_THE_BLANK_SELECTED_BOX_SHADOW: inset 0px 0px 0px 2px $BLANK_UNDERLINE_SELECTED_COLOR,
    inset 0px 0px 12px 0px $BLANK_UNDERLINE_SELECTED_COLOR;
$BLANKS_ON_IMAGE_SELECTED_BOX_SHADOW: inset 0px 0px 0px 2px rgba(#fff, 0.8),
    0px 0px 2px 1px rgba($BLANK_UNDERLINE_SELECTED_COLOR, 0.8);

// Useful dark colors

$COLOR_V3_PLUM: #600447;
$COLOR_V3_EGGPLANT: #35092c;
$COLOR_V3_BLACK: #010101;

// map colors
$COLOR_V3_MAP_BLUE_DARKER: #464f5d;
$COLOR_V3_MAP_BLUE_DARK: #5c687c;
$COLOR_V3_MAP_BLUE_MID: #9ca8b9;
$COLOR_V3_MAP_BLUE_MIDLIGHT: #d7dbe2;
$COLOR_V3_MAP_BLUE_LIGHT: #eef0f3;

// Registration and Login colors

$LOGIN_REGISTER_TEXT_GRAY: #615a52;
$COLOR_MARKETING_CORAL_HOVER: #e54558;
$COLOR_MARKETING_TURQUOISE: #14cca7;

// Miscellaneous marketing colors from pixelmatters
$COLOR_MARKETING_LIGHT_GRAY: #ccc;
$COLOR_MARKETING_GRAY: #aaa;

$COLOR_V3_SIDE_NAV_ACTIVE: $COLOR_V3_GREY_DARKER;
$COLOR_V3_MESSAGE_BOX_COLOR: #2d3e53;
$COLOR_V3_BLUISH_GRAY_COLOR: $COLOR_V3_MESSAGE_BOX_COLOR;
$COLOR_V3_BLUISH_GRAY_LIGHT_COLOR: #d8dade;

// Valar Landing/Login/Signup Pages
$COLOR_V3_PRIMARY_GRAY: #b1b1b1;
$COLOR_V3_NEUTRAL_100: #e7e8eb;
$COLOR_V3_NEUTRAL_200: #cfd1d6;
$COLOR_V3_NEUTRAL_400: #878b99;
$COLOR_V3_NEUTRAL_500: #6f7485;
$COLOR_V3_NEUTRAL_700: #3f465c;
$COLOR_V3_SECONDARY_DEEP_BLUE: #1f45b6;
