//---------------------------
// Scrollbars
//---------------------------

::-webkit-scrollbar {
    width: $WEBKIT_SCROLLBAR_WIDTH;

    &:horizontal {
        height: 8px;
    }
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-track-piece {
    background-color: rgba($COLOR_V3_BLACK, 0.15);

    &:start:vertical {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    &:end:vertical {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &:start:horizontal {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    &:end:horizontal {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

::-webkit-scrollbar-thumb {
    background-color: rgba($COLOR_V3_BLACK, 0.4);
    border-radius: 4px;
}
