@font-face {
    font-family: "Apercu Regular Pro";
    src: url("~fonts/apercu-regular-web/apercu-regular.eot");
    src: url("~fonts/apercu-regular-web/apercu-regular.woff2") format("woff2"),
        url("~fonts/apercu-regular-web/apercu-regular.woff") format("woff"),
        url("~fonts/apercu-regular-web/apercu-regular.ttf") format("truetype"),
        url("~fonts/apercu-regular-web/apercu-regular.eot?#iefix") format("embedded-opentype");
    font-display: fallback;
    font-style: normal;
}

@font-face {
    font-family: "Apercu Regular Italic Pro";
    src: url("~fonts/apercu-italic-web/apercu-italic.eot");
    src: url("~fonts/apercu-italic-web/apercu-italic.woff2") format("woff2"),
        url("~fonts/apercu-italic-web/apercu-italic.woff") format("woff"),
        url("~fonts/apercu-italic-web/apercu-italic.ttf") format("truetype"),
        url("~fonts/apercu-italic-web/apercu-italic.eot?#iefix") format("embedded-opentype");
    font-display: fallback;
    font-style: normal;
}

@font-face {
    font-family: "Apercu Medium Pro";
    src: url("~fonts/apercu-medium-web/apercu-medium.eot");
    src: url("~fonts/apercu-medium-web/apercu-medium.woff2") format("woff2"),
        url("~fonts/apercu-medium-web/apercu-medium.woff") format("woff"),
        url("~fonts/apercu-medium-web/apercu-medium.ttf") format("truetype"),
        url("~fonts/apercu-medium-web/apercu-medium.eot?#iefix") format("embedded-opentype");
    font-display: fallback;
    font-style: normal;
}

@font-face {
    font-family: "Apercu Bold Pro";
    src: url("~fonts/apercu-bold-web/apercu-bold.eot");
    src: url("~fonts/apercu-bold-web/apercu-bold.woff2") format("woff2"),
        url("~fonts/apercu-bold-web/apercu-bold.woff") format("woff"),
        url("~fonts/apercu-bold-web/apercu-bold.ttf") format("truetype"),
        url("~fonts/apercu-bold-web/apercu-bold.eot?#iefix") format("embedded-opentype");
    font-display: fallback;
    font-style: normal;
}

@font-face {
    font-family: "Lab Grotesque Regular";
    src: url("~fonts/lab-grotesque/LabGrotesque-Regular.woff") format("woff"), url("~fonts/lab-grotesque/LabGrotesque-Regular.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Lab Grotesque Medium";
    src: url("~fonts/lab-grotesque/LabGrotesque-Medium.woff") format("woff"), url("~fonts/lab-grotesque/LabGrotesque-Medium.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Lab Grotesque Bold";
    src: url("~fonts/lab-grotesque/LabGrotesque-Bold.woff") format("woff"), url("~fonts/lab-grotesque/LabGrotesque-Bold.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Montserrat Regular";
    src: url("~fonts/montserrat/Montserrat.woff") format("woff"),
        url("~fonts/montserrat/Montserrat.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Montserrat Medium";
    src: url("~fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Montserrat SemiBold";
    src: url("~fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Montserrat Bold";
    src: url("~fonts/montserrat/Montserrat-Bold.woff") format("woff"),
        url("~fonts/montserrat/Montserrat-Bold.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}


