body.idology {
    margin: 0;
    padding: 0;
    font: 15px/21px $FONT_FAMILY;

    &,
    * {
        box-sizing: border-box;
    }
}

#loading {
    position: absolute;
    top: 50%;
    padding: 20px;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;

    &.hide {
        display: none;
    }
}

#idology-content {
    display: none;

    &.show {
        display: block;
    }
}

body.scan {
    background: $COLOR_V3_BEIGE;

    .idology-message.hide-mobile {
        display: none;
    }

    @media (min-width: 740px) {
        .idology-message.hide-mobile {
            display: block;
        }

        .idology-iframe {
            display: none;
        }
    }
}

.idology-message {
    position: absolute;
    top: 50%;
    padding: 20px;
    width: 100%;
    transform: translateY(-50%);

    text-align: center;
    color: $COLOR_V3_GREY_DARKER;

    b {
        font-weight: $FONT_WEIGHT_SEMIBOLD;
    }

    h1 {
        font-size: 24px;
        font-weight: normal;
        color: $COLOR_V3_BLACK;
        margin: 0;
    }

    p {
        margin: 30px auto;
        max-width: 280px;
    }

    .icon {
        svg {
            display: block;
            margin: 0 auto;
        }
    }

    &.firefox-mobile {
        display: none;

        &.show {
            display: block;
        }
    }
}

.idology-iframe {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.show-firefox-mobile {
        display: none;
    }
}

.important-tips {
    background: $COLOR_V3_BEIGE_LIGHT;
    border: solid $COLOR_V3_BEIGE_MIDDARK;
    border-width: 1px 0;
    padding: 20px 10px 5px;
    margin: 0 -20px;
    font-size: 14px;

    &::after {
        content: "";
        display: block;
        width: 100%;
        clear: both;
    }

    h3 {
        text-align: center;
        margin: -31px 0 10px;

        span {
            display: inline-block;
            background: $COLOR_V3_WHITE;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-size: 14px;
            text-transform: uppercase;
            border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
            border-radius: 3px;
            padding: 2px 10px;
        }
    }

    div {
        padding: 0 15px;

        @media (min-width: $screen-sm-min) {
            width: 50%;
            float: left;
        }
    }

    img {
        margin: 10px 0;

        &.bump {
            @media (min-width: $screen-sm-min) {
                margin-top: 26px;
            }
        }
    }

    ul {
        margin: 0 0 20px;
        padding: 0;
    }

    li {
        margin: 5px 0;
        padding: 0;
        list-style: none;
    }
}
