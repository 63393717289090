selected-pills {
    $fontSize: 13px;

    span.subtitle {
        text-align: center;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
        font-size: 14px;
        display: block;
        margin-bottom: 30px;

        @media (min-width: $screen-sm-min) {
            font-size: 16px;
        }

        b {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
        }
    }

    .section-container {
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
    }

    .pills-container {
        width: 100%;
        margin-bottom: 30px;
    }

    .pill-wrapper {
        margin: 0 3px 5px;
        display: inline-block;

        .pill {
            border-radius: 9999px;
            background-color: #e2e4e7;
            color: $COLOR_V3_BLUISH_GRAY_COLOR;
            display: block;
            padding: 5px 10px 4px;
            font-size: $fontSize;
            line-height: $fontSize;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            cursor: move;
        }
    }

    pills-select[locale-key="interests"] & .pill-wrapper .pill {
        border-radius: 4px;
        background: $COLOR_V3_WHITE;
        border: 1px solid $COLOR_V3_BEIGE_DARKER;
        color: $COLOR_V3_BEIGE_DARKEST;
        position: relative;
        padding-left: 25px;

        .delete-btn {
            position: absolute;
            top: 50%;
            left: 9px;
            transform: translateY(-50%);
            margin-top: 0;
        }

        &::before {
            content: "#";
            font-family: Arial, sans-serif;
            margin-right: 2px;
        }
    }

    .sortable-chosen .pill {
        background-color: rgba($COLOR_V3_BLUE_LIGHT, 0.4);
    }

    .delete-btn {
        border: none;
        outline: none;
        background: none;
        color: $COLOR_V3_BLUISH_GRAY_COLOR;
        font-size: 10px;
        line-height: $fontSize;
        cursor: pointer;
        transition: 0.25s ease;
        padding: 5px;
        display: inline-block;
        vertical-align: middle;
        margin: -5px 0 -5px -5px;

        html[dir="rtl"] & {
            margin: -5px -5px -5px 0;
        }

        &:hover {
            color: $COLOR_V3_RED;
        }
    }
}
